import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import moment from "moment";
import _ from "lodash";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { BackArrowIcon, CloseIcon, TakePictureIcon } from "../../components/icons";
import { Formik, ErrorMessage } from "formik";
import "./styles.scss";
import { assignInboundSchemaTab2, assignInboundSchemaTab1, driverSchema } from "../../utils/validations";
import { numberFormatLbs, truckTypes , numberFormat, customStartCase} from "../../constants/common";
import NumberFormat from "react-number-format";
import CreateDriver from "./partials/create-driver";
import NewDriver from "./partials/new-driver";
import { Drawer, Spin } from "antd";
import CreateCustomer from "../CreateCustomer/container"
// import ReactWebcam from "./partials/react-webcam";
import { LoadingOutlined } from '@ant-design/icons';
import CreateProject from "./partials/create-projects";
import { DebounceInput } from "react-debounce-input";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const searchLimit = 100;
const defaultLimit = 250;
export default class CreateOrderComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);
    const local = window.localStorage.getItem("weightData")
      ? JSON.parse(window.localStorage.getItem("weightData"))
      : {};
    this.state = {
      isCreateDrawerOpen: false,
      isCompany: false,
      isDriver: false,
      isTruck: false,
      modalIsOpen: false,
      weightData: local,
      companySearchText: "",
      pricingTierDumpRates: {},
      pricingTierId: "",
      dumpRateType: "",
      dumpRate:"",
      driverSearchText: "",
      truckSearchText: "",
      typingTimeout: 0,
      customers: [],
      dueBalance: 0,
      projectsData:[],
      selectedReadingType:"Inbound material sale",
      customer: null,
      selectedCustomer: null,
      selectedCustomerSearch: null,
      selectedDriverSearch:null,
      selectedTruckSearch:null,
      drivers: [],
      selectedDriver: null,
      driver: null,
      trucks: [],
      selectedTruck: null,
      truck: null,
      truckTab: 1,
      isCameraOn: false,
      isCameraOnLoad: false,
      plateImageUrl: "",
      loadImageUrl: "",
      initialValues: {
        customer: "",
        driver: "",
        reading_type: local.weightType,
        inboundWeight: local.weight,
        inboundWeightDate: local.date,
        defaultOutboundWeight: "",
        containerCheck:true,
        project:""
      },
      driverInitialValues: {
        firstName: "",
        lastName: "",
        phone: "",
        state: "New York",
        licenseNumber: "",
        customerId: ""
      },
      values: {},
      createDriverSidebar: false,
      isLicenseCamera: false,
      licenseImageUrl: "",
      sortOrder: { companyName: 1 },
      createDriverForm: false,
      rotation: 0,
      orderType: local.manuallyEnterWeight ? "manual" : "auto",
      requiredFields: ["customer"],
      user: null,
      visible: false,
      isCreateCustomerModelOpen:false,
      overAllData:[],
      data_type: 'customer',
      SearchText:"",
      showSearch:false,
      isLoading:false,
      containers:{},
      allContainers:[],
      allTrucks:[],
      containersFromInput:[],
      containerCheck:true,
      isProjectDrawerOpen:false,
      createProjectSideBar:false,
      selectedProject:null,
      projectSearchText:"",
      projectSpinning:false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.fetchCustomers(defaultLimit);
    this.fetchMe();
    const truckData = JSON.parse(localStorage.getItem("truckData"));
    const local = window.localStorage.getItem("weightData")
      ? JSON.parse(window.localStorage.getItem("weightData"))
      : {};
    if (truckData) {
      const { truckTab, loadImageUrl, plateImageUrl, initialValues } = truckData;
      this.setState({
        truckTab,
        loadImageUrl,
        plateImageUrl,
        initialValues: {
          ...initialValues,
          customer: "",
          driver: "",
          reading_type: local.weightType,
          inboundWeight: local.weight,
          inboundWeightDate: local.date,
          defaultOutboundWeight:""
        }
      });
    }
  };

  async fetchMe() {
    const {
      value: { user }
    } = await this.props.fetchMe();
    this.setState({ user });
  }

  handleCompanySearch = event => {
    const searchText = event.target.value.trimStart();
    this.setState({
      companySearchText: searchText,
      isLoading: searchText.length > 0,  
      customers: searchText.length > 0 ? this.state.customers : [],
    }, () => {
      if (searchText.length > 0) {
        this.fetchCustomers(searchLimit);
      }else{
        this.setState({customers:[]})
      }
    });
  };

  setDumpRate = (value) => {
    // const {
    //   order: {
    //     customer: { dumpRates }
    //   }
    // } = this.state;
    const dumpRates = this.state.pricingTierDumpRates;
    if (value === "defaultOption") {
      this.setState(
        { dumpRateType: value, dumpRate: dumpRates[dumpRates[value]], initialValues: { ...this.state.initialValues, dumpRate: dumpRates[value] } },
        () => {
          if (dumpRates[value].indexOf("Yard") !== -1) {
            this.setState({ showYardageValue: true });
          }
          if (dumpRates[value].indexOf("Yard") === -1) {
            this.setState({ showYardageValue: false });
          }
          //setValues({ ...values, dumpRate: dumpRates[value] });
        }
      );
    } else {
      this.setState({ dumpRateType: value, dumpRate: dumpRates[value], initialValues: { ...this.state.initialValues, dumpRate: value } }, () => {
        if (value.indexOf("Yard") !== -1) {
          this.setState({ showYardageValue: true });
        }
        if (value.indexOf("Yard") === -1) {
          this.setState({ showYardageValue: false });
        }
        //setValues({ ...values, dumpRate: value });
      });
    }
  };

  onChangeDumpRate = (e, values, setValues) => {
    const dumpRates = this.state.pricingTierDumpRates;
    const value = e.target.value;
    if (value === "defaultOption") {
      this.setState(
        { dumpRateType: value, dumpRate: dumpRates[dumpRates[value]] },
        () => {
          if (dumpRates[value].indexOf("Yard") !== -1) {
            this.setState({ showYardageValue: true });
          }
          if (dumpRates[value].indexOf("Yard") === -1) {
            this.setState({ showYardageValue: false });
          }
          this.setState({
            initialValues: {
              ...values,
              dumpRate: value
            },
          })
          setValues({ ...values, dumpRate: dumpRates[value] });
        }
      );
    } else {
      this.setState({ dumpRateType: value, dumpRate: dumpRates[value] }, () => {
        if (value.indexOf("Yard") !== -1) {
          this.setState({ showYardageValue: true });
        }
        if (value.indexOf("Yard") === -1) {
          this.setState({ showYardageValue: false });
        }
        this.setState({
          initialValues: {
            ...values,
            dumpRate: value
          },
        })
        setValues({ ...values, dumpRate: value });
      });
    }
  };

  assignPricingTier = async(pricingTierId)=>{
    let DumpRatesData = {};
    let additionalItems = [];
    const {
      value: { data, status },
    } = await this.props.getPricingTierById(pricingTierId);
    if (status) {
     let pricingTier = data;
      const dumpRatesObject = data?.pricing?.dumpRates;
      const dumratesArray = Object.keys(data?.pricing?.dumpRates);
      dumratesArray.forEach((item) => {
        if (dumpRatesObject[item] > 0) {
          DumpRatesData[`${item}`] = dumpRatesObject[item];
        }
      });
      let Add = pricingTier?.pricing?.additionalItems;
      Add.forEach((item) => {
        if (item?.value > 0) {
          additionalItems.push(item);
        }
      });
      if (Number(pricingTier?.pricing?.capoutPrice) > 0) {
        this.setState({ isCapoutPrice: true });
      }else{
        this.setState({ isCapoutPrice: false });
      }

      this.setState({
        pricingTierDumpRates: this.sortPricingTier(dumpRatesObject),
        additionalItemsData: additionalItems,
        capoutPrice: Number(pricingTier?.pricing?.capoutPrice),
        minimumPrice: Number(pricingTier?.pricing?.minimumPrice),
        pricingTier: pricingTier?._id,
        pricingTierName: pricingTier?.pricing?.pricingTier,
        pricingTierId: this.state.customer.pricingTier,
      });
      return pricingTier;
    }
  }
   sortPricingTier = (data) => {
    const sortedData = Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== null) {
            if (key.startsWith("pricePerTon") || key.startsWith("pricePerYard")) {
                acc.tieredPrices[key] = value;
            } else {
                acc.otherPrices[key] = value;
            }
        }
        return acc;
    }, { tieredPrices: {}, otherPrices: {} });

    return { ...sortedData.tieredPrices, ...sortedData.otherPrices };
};
fetchProject=async()=>{
  this.setState({projectSpinning:true})
  const payload={
    id: this.state.customer?._id,
    searchText:this.state.projectSearchText,
   }
  const {
    value: {
      data
    }
  } = await this.props.fetchProjects(payload);
  this.setState({ projectsData:data,projectSpinning:false});
}

  handleDriverSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      driverSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchDrivers();
      }, 200)
    });
  };

  handleProjectSearch = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      projectSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchProject();
      }, 200),
    });
  };
  handleTruckSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      truckSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchTrucks();
      }, 200)
    });
  };

  fetchCustomers = async (limit) => {
    const { fetchCustomers } = this.props;
    const { companySearchText, sortOrder } = this.state;
    const {
      value: {
        data: { customers }
      }
    } = await fetchCustomers({ searchText: companySearchText, status: 1, sortOrder, limit, isDisableCount: true });
    this.setState({ customers ,isLoading:false });
  };

  fetchContainers = async () => {
    const { fetchContainers } = this.props;
    const {
      value: {
        data: { containers }
      }
    } = await fetchContainers();
    const allContainers = containers.map(v => ({...v,defaultWeight:""}))
    this.setState({ allContainers,initialValues:{...this.state.initialValues} });
  };

  fetchDrivers = async () => {
    const { fetchDriversByCustomerId } = this.props;
    const { driverSearchText } = this.state;
    const {
      value: { data }
    } = await fetchDriversByCustomerId({
      customerId: this.state.customer._id,
      searchText: driverSearchText
    });
    this.setState({ drivers: data });
  };

  isDelinquentAccount = async () => {
    const payload = {
      customerId:this.state.customer._id,
      billingStatus: '',
    }
    const { value: { data: { allData } } } = await this.props.customerInvoicesByCustomerId(payload);
    this.setState({dueBalance : allData?.delinquentBalance})
  };

  fetchTrucks = async () => {
    const { fetchTrucksByCustomerId } = this.props;
    const { truckSearchText } = this.state;
    const {
      value: { data }
    } = await fetchTrucksByCustomerId({
      customerId: this.state.customer._id,
      searchText: truckSearchText
    });
    const values = this.state.initialValues
    let macthData = {type:values?.type !== undefined ? values?.type: data?.[0]?.type, plateNumber: values?.plateNumber !== undefined ? values?.plateNumber: data?.[0]?.plateNumber, number: values?.number !== undefined ? values?.number: data?.[0]?.number  }
    const {truckHere,containers} = this.checkTrucks(macthData,data)
    if(truckHere.length > 0){
      this.setState({containersFromInput:containers})
    }
    this.setState({ trucks: data });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  selectCustomer = customer => {
    const pricingTierId = customer?.pricingTier;
    this.setState({ selectedCustomer: customer,selectedTruckSearch:null,pricingTierId: pricingTierId });
  };

  assignNewCustomer = async (selectedCustomer,projects) =>{
    this.setState({selectedCustomer})
    await new Promise(resolve => this.setState({}, resolve));
    this.assignCustomer("new")
    this.setState({ projectsData:projects,projectSpinning:false});
  }

  assignCustomer = (status) => {
    const { initialValues, selectedCustomer } = this.state;
    this.setState(
      {
        customer: selectedCustomer,
        selectedTruck:null,
        isCreateDrawerOpen: false,
        isCompany: false,
        selectedProject:null,
        isDriver: false,
        isTruck: false,
        visible: false,
        initialValues: {
          ...initialValues,
          customer: _.toLower(selectedCustomer && selectedCustomer.displayName),
          driver: "",
          defaultOutboundWeight:"",
          project :"",
          containers:{}
        },
        containers:{},
        driver: null,
        drivers:[],
        trucks:[],
        dumpRateType:"",
      },
      async() => {
        if (selectedCustomer) {
          if(status !== "new"){
            this.fetchDrivers();
            this.fetchTrucks();
            this.fetchProject();
            this.isDelinquentAccount();
            const pricingTier= await this.assignPricingTier(selectedCustomer?.pricingTier);
          this.setDumpRate(
            _.get(pricingTier, "pricing.dumpRates.defaultOption", "")
          );
          }
          this.fetchCustomers(searchLimit);
        }
      }
    );
  };

  selectDriver = obj => {
    this.setState({ selectedDriver: obj,selectedTruckSearch:null });
  };
 
selectedProject=obj=>{
  this.setState({selectedProject:obj})
}

assignProjects = () => {
  const { initialValues,selectedProject,selectedDriver } = this.state;
  this.setState({
    driver: selectedDriver,
    isCreateDrawerOpen: false,
    isCompany: false,
    isDriver: false,
    isProjectDrawerOpen:false,
    isTruck: false,
    visible: false,
    selectedTruckSearch:null,
    initialValues: {
      ...initialValues,
      project: _.toLower(selectedProject && `${selectedProject.projectName}`),
      // defaultOutboundWeight:"",
    }
  });
};
  assignDriver = () => {
    const { initialValues, selectedDriver } = this.state;
    this.setState({
      driver: selectedDriver,
      isCreateDrawerOpen: false,
      isCompany: false,
      isDriver: false,
      isTruck: false,
      visible: false,
      selectedTruckSearch:null,
      initialValues: {
        ...initialValues,
        driver: _.toLower(selectedDriver && `${selectedDriver.firstName}, ${selectedDriver.lastName}`),
        // defaultOutboundWeight:"",
      }
    });
  };


  selectTruck = obj => {
    this.setState({ selectedTruck: obj });
  };

  assignTruck = () => {
    const { initialValues, selectedTruck } = this.state;
    if(selectedTruck.containers.length > 0){
      const containers = selectedTruck.containers.sort((a, b) =>Number( a.size) - Number(b.size));
      selectedTruck.containers = containers
    }
    this.setState({
      truck: selectedTruck,
      isCreateDrawerOpen: false,
      isCompany: false,
      visible: false,
      isDriver: false,
      isTruck: false,
      initialValues: {
        ...initialValues,
        defaultOutboundWeight: selectedTruck.defaultOutboundWeight ? selectedTruck.defaultOutboundWeight : "",truck:selectedTruck
      }
    });
    if(selectedTruck.containers.length > 0){
      this.setState({ initialValues: {
        ...initialValues,
        defaultOutboundWeight: selectedTruck.containers[0].defaultWeight,
        containers:selectedTruck.containers[0]
      }})
    }
  };

  setTruckTab = tab => {
    const { initialValues } = this.state;
    let values = {};
    let requiredFields = [...this.state.requiredFields];
    if (tab === 2) {
      this.setState({plateImageUrl: ""})
      const truckData = JSON.parse(localStorage.getItem("truckData"));
      values = {
        ...initialValues,
        number: _.get(truckData, "initialValues.number", ""),
        plateImageUrl: _.get(truckData, "plateImageUrl", ""),
        plateNumber: _.get(truckData, "initialValues.plateNumber", ""),
        type: _.get(truckData, "initialValues.plateImageUrl", "Box Truck"),
        defaultOutboundWeight: _.get(truckData, "initialValues.defaultOutboundWeight", ""),
        containerCheck:true,
        containers:{}
      };
      this.fetchContainers()
      requiredFields = ["customer", "plateNumber", "type"];
      this.setState({truck:null})
    } else {
      const { customer, driver, inboundWeightDate, inboundWeight, reading_type } = initialValues;
      values = {
        customer,
        driver,
        inboundWeightDate,
        inboundWeight,
        reading_type,
        containers:{},
        defaultOutboundWeight:""
      };
      requiredFields = ["customer"];
      const truck = this.state.selectedTruck
      if(truck){
        values.defaultOutboundWeight = truck.defaultOutboundWeight
        values.containers = truck.containers
        this.setState({truck})
      }
    }
    this.setState({ truckTab: tab, initialValues: values,requiredFields,containers:{} });
  };

  async onCropImage(blob) {
    const licenseImageUrl = await this.convertBlobToBase64(blob);
    this.setState({ licenseImageUrl });
  }

  async setInitialState({ state: { firstName, lastName, licenseNumber, formdata, state } }) {
    const { driverInitialValues } = this.state;
    if (formdata !== "") {
      const licenseImageUrl = await this.convertBlobToBase64(formdata);
      this.setState({ licenseImageUrl, isLicenseCamera: false });
    }
    this.setState({
      createDriverForm: true,
      createDriverSidebar: false,
      driverInitialValues: { ...driverInitialValues, firstName, lastName, licenseNumber , state}
    });
  }
 
  onTakePhoto = async (dataUri, field, field2) => {
    this.setState({ [field]: dataUri, [field2]: false });
  };

  dataURItoBlob = dataURI => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };


  convertBlobToBase64(blob) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function() {
        var base64data = reader.result;
        return resolve(base64data);
      };
    });
  }

  submitDriverForm = async (values, { setErrors }) => {
    try {
      const {
        customer: { _id },
        licenseImageUrl,
        rotation
      } = this.state;
      let url = "";
      if (licenseImageUrl) {
        const uploadImage = await this.props.uploadImage(this.submitImages(licenseImageUrl));
        url = uploadImage?.value?.url?.length > 0 ? uploadImage?.value?.url : licenseImageUrl;
      }
      const payload = { ...values, customerId: _id, licenseImageUrl: url, rotation };
      const {
        value: { status, message }
      } = await this.props.createDriver(payload);
      if (status) {
        this.setState({
          createDriverSidebar: false,
          isDriver: true,
          createDriverForm: false,
          licenseImageUrl: "",
          rotation: 0
        });
        this.fetchDrivers();
      } else {
        setErrors({ state: message });
      }
    } catch (err) {
      setErrors({ state: "Upload driver license image" });
    }
  };

  submitImages(data) {
    const blob = this.dataURItoBlob(data);
    const formdata = new FormData();
    formdata.append("photos", blob);
    formdata.append("fileName", `${new Date().getTime()}.png`);
    formdata.append("folderName", "load");
    formdata.append("fileType", "png");
    return formdata;
  }

  handleSubmit = async () => {
    const { history, uploadImage, createOrder } = this.props;
    const {
      plateImageUrl,
      customer,
      truck,
      driver,
      initialValues: {
        reading_type,
        inboundWeight,
        inboundWeightDate,
        plateNumber,
        type,
        number,
        containers,
        defaultOutboundWeight,
      },
      loadImageUrl,
      dumpRateType,
      selectedReadingType,
      dumpRate,
      orderType,
      containerCheck
    } = this.state;
    this.setSubmitting(true);
    let loadImage = "";
    let plateImage = "";
    if (truck && loadImageUrl) {
      const img1 = await uploadImage(this.submitImages(loadImageUrl));
      loadImage = img1.value.url;
    }
    if (!truck) {
      if (loadImageUrl) {
        const img1 = await uploadImage(this.submitImages(loadImageUrl));
        loadImage = img1.value.url;
      }
      if (plateImageUrl) {
        const img1 = await uploadImage(this.submitImages(plateImageUrl));
        plateImage = img1.value.url;
      }
    }
    const container = containers
    const containerWithoutId = {size:container?.size,defaultWeight:container?.defaultWeight}
    const payload = {
      customerId: customer._id,
      driverId: driver ? driver._id :null,
      truckId: truck ? truck._id : "",
      reading_type,
      inboundWeight,
      inboundWeightDate,
      plateNumber,
      selectedReadingType,
      projectId:this.state.selectedProject?._id,
      number,
      type,
      plateImageUrl: plateImage,
      loadImageUrl: loadImage,
      paymentTerms: customer?.paymentTerms,
      dumpRateType:dumpRateType,
      dumpRate :dumpRate,
      defaultOutboundWeight:containerCheck && defaultOutboundWeight ? String(defaultOutboundWeight).replace(/,/g, "") : "",
      orderType,
      containers:containerWithoutId,
      containerSize:container?.size ?? ""
    };
    if(!containerCheck){
      delete payload.containers
    }
    const {
      value: { status }
    } = await createOrder(payload);
    if (status) {
      localStorage.removeItem("truckData");
      history.push("/scale");
    }
  };

  showDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({
      visible: false,
      rotation: 0,
      licenseImageUrl: "",
      isLicenseCamera: true
    });
  };

  checkTrucks = (macthData,trucks) => {
    const truckHere = trucks?.filter(t => t.type === (macthData?.type?.length > 0 ? macthData?.type : this.state?.initialValues?.truckType)
       && t?.plateNumber === macthData?.plateNumber && t?.number === macthData?.number
       )
    let containers = []
    truckHere.map((v)=> {
      containers = [...containers,...v.containers]
      return null
    })
    return {truckHere,containers}
  }
  /* checkTrucks = (macthData,trucks) => {
    const truckHere = trucks.filter(t => t.type === macthData.type && t.plateNumber === macthData.plateNumber && t.number === macthData.number)
    let containers = []
    truckHere.map((v)=> {
      containers = [...containers,...v.containers]
      return null
    })
    return {truckHere,containers}
  } */

  setValues(e, values, setValues) {
    const {name , value} = e.target
    let macthData = {type:values?.type,plateNumber:values?.plateNumber,number:values?.number  }
    macthData = {...macthData,[name]:value}
    const {truckHere,containers} = this.checkTrucks(macthData,this.state.trucks)
    let initialValues = this.state.initialValues
    if(truckHere.length > 0){
      if(containers.some(c => c.size === this.state.containers.size)){
        this.setState({containers:{}})
        values.defaultOutboundWeight = name !== "defaultOutboundWeight" && ""
        initialValues.defaultOutboundWeight = name !== "defaultOutboundWeight" && ""
      }
      this.setState({containersFromInput:containers})
    }else{
      this.setState({containersFromInput:[]})
    }
    this.setState({ initialValues: { ...initialValues, [e.target.name]: e.target.value } });
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  navigate = () => {
    // this.props.history.push("/customer");
    this.setState({isCreateCustomerModelOpen:true})
    const { truckTab, loadImageUrl, plateImageUrl, initialValues } = this.state;
    const data = { truckTab, loadImageUrl, plateImageUrl, initialValues };
    localStorage.setItem("truckData", JSON.stringify(data));
  };
  closeModel=()=>{
    this.setState({isCreateCustomerModelOpen:false})
  }

  handleOverAllSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    const searchText = event.target.value;
    const showSearch = searchText.length >= 3;
    this.setState({
      SearchText: event.target.value.trimStart(),
      showSearch:showSearch,
      selectedCustomerSearch:null,
      // customer:null,
      selectedTruckSearch:null,
      isLoading:true,
      typingTimeout: setTimeout(() => {
        this.fetchOverAll();
      }, 200)
    });
  };
  fetchOverAll = async () => {
    const { fetchTrucksByCompanyId } = this.props;
    const { SearchText } = this.state;
    const {
      value: { data, data_type }
    } = await fetchTrucksByCompanyId({
      companyId: this.state.user && this.state?.user?.companyId,
      searchText:SearchText.trimStart()
    });
    
    this.setState({ overAllData: data, data_type, isLoading:false, });
  };

  selectCustomerSearch = customer => {
    
    this.setState({ selectedCustomerSearch: customer, showSearch: false,SearchText:'' }, () => {
      if (!this.state.showSearch) {
        this.assignCustomerSearch();
      }
    });
  };
  assignCustomerSearch = () => {
    const { initialValues, selectedCustomerSearch } = this.state;
    this.setState(
      {
        customer: selectedCustomerSearch,
        isCreateDrawerOpen: false,
        isCompany: false,
        isDriver: false,
        isTruck: false,
        visible: false,
        selectedProject:null,
        selectedTruck:null,
        initialValues: {
          ...initialValues,
          customer: _.toLower(selectedCustomerSearch && selectedCustomerSearch.displayName),
          driver: "",
          defaultOutboundWeight:"",
          project :"",
        },
        driver: null
      },
      async () => {
        if (selectedCustomerSearch) {
          this.fetchDrivers();
          this.fetchTrucks();
          this.fetchProject();
          this.isDelinquentAccount();
          const pricingTier= await this.assignPricingTier(selectedCustomerSearch?.pricingTier);
          this.setDumpRate(
            _.get(pricingTier, "pricing.dumpRates.defaultOption", "")
          );
        }
      }
    );
  };
  selectedDriverSearch = (customer,driver,truck) => {
    this.setState({ selectedCustomerSearch: customer,selectedDriverSearch: driver, selectedTruckSearch : truck, selectedTruck: truck, showSearch: false,SearchText:'' }, () => {
      if (!this.state.showSearch) {
        this.assignDriverSearch();
      }
    });
  };
  assignDriverSearch = () => {
    let { initialValues, selectedDriverSearch ,selectedCustomerSearch,selectedTruckSearch} = this.state;
    if(selectedTruckSearch?.containers?.length > 0){
      let containers = selectedTruckSearch.containers.sort((a, b) =>Number( a.size) - Number(b.size));
      selectedTruckSearch.containers = containers
    }
    this.setState({
      customer: selectedCustomerSearch,
      driver: selectedDriverSearch,
      truck: selectedTruckSearch,
      isCreateDrawerOpen: false,
      isCompany: false,
      isDriver: false,
      isTruck: false,
      visible: false,
      selectedTruck: this.state?.selectedTruck?._id ? this.state?.selectedTruck : null,
      initialValues: {
        ...initialValues,
        customer: _.toLower(selectedCustomerSearch && selectedCustomerSearch.displayName),
        driver: _.toLower(selectedDriverSearch && `${selectedDriverSearch?.firstName}, ${selectedDriverSearch?.lastName}`),
        defaultOutboundWeight: selectedTruckSearch?.defaultOutboundWeight ? selectedTruckSearch?.defaultOutboundWeight :  selectedTruckSearch && selectedTruckSearch?.containers && selectedTruckSearch?.containers.length > 0 ?  selectedTruckSearch?.containers?.[0].defaultWeight : 0,
        containers:selectedTruckSearch?.containers?.[0]
      }
      
    },async () => {
        if (selectedCustomerSearch) {
          this.fetchDrivers();
          this.fetchTrucks();
          this.fetchProject();
          const pricingTier= await this.assignPricingTier(selectedCustomerSearch?.pricingTier);
          this.setDumpRate(
            _.get(pricingTier, "pricing.dumpRates.defaultOption", "")
          );
        }
      });
  };


changeContainer = (e,setValues,values,truckHere)=>{
  if(e === ""){
    setValues({
      ...values,
      defaultOutboundWeight:"",
      containers:{}
    });
    this.setState({
      initialValues: { ...this.state.initialValues, defaultOutboundWeight:"", containers:{}},containers:{} 
    });
  }else{
    const container = truckHere ? this.state.truck?.containers?.filter(c => String(c.size) === String(e)) : this.state.allContainers.filter(c => String(c.size) === String(e))
    setValues({
      ...values,
      defaultOutboundWeight:container[0]?.defaultWeight,
      containers:container
    });
    this.setState({
      initialValues: { ...this.state.initialValues, defaultOutboundWeight:container[0]?.defaultWeight,containers:container[0] },containers:container[0] 
    });
  }
}

checkAlready = (size)=>{
  const containers = this.state.containersFromInput.find(c => String(c.size) === String(size)) 
  return containers ? true : false
}

handleRadioChange = (event) => {
  this.setState({ selectedReadingType: event.target.value });
};
  render() {
    const {
      isCameraOn,
      isCameraOnLoad,
      isCompany,
      isDriver,
      isTruck,
      weightData,
      customers,
      customer,
      selectedCustomer,
      drivers,
      driver,
      selectedDriver,
      trucks,
      selectedTruck,
      truckTab,
      dueBalance,
      plateImageUrl,
      initialValues,
      loadImageUrl,
      driverInitialValues,
      createDriverSidebar,
      createDriverForm,
      rotation,
      requiredFields,
      user,
      showSearch,
      overAllData,
      selectedReadingType,
      data_type,
      selectedCustomerSearch,
      //selectedDriverSearch,
      selectedTruckSearch
    } = this.state;
    const date = weightData && weightData.date ? moment(weightData.date).format("MMM DD, YYYY") : "";
    const time = weightData && weightData.date ? moment(weightData.date).format("LT") : "";
    return (
      <div>
        <div className="creat__order-wrapper">
          <div className="custome__container">
            <div className="btn-back-header">
              <button className="btn btn-link btn-back">
                <Link to={"/scale"}>
                  <BackArrowIcon /> Back
                </Link>
              </button>
            </div>
            <div className="card">
              <div className="card-header d-flex flex-column align-items-start">
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="card-title">Weight <br /> <span className="weight-font">{numberFormatLbs(weightData && weightData.weight)}</span><span className="lbs-font">LBS</span></h5>
                    <p className="card-title-sm">
                      Date - {date} at {time} EST
                    </p>
                    {customer && dueBalance > 0 && customer?.paymentTerms === "account" ? (
              <div className="ticket-void-order-text mt-2">
                {customer?.displayName} has an overdue balance of ${numberFormat(dueBalance)}
              </div>
            ) : null}
                  </div>
                </div>                
              </div>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={truckTab === 1 ? assignInboundSchemaTab1 : assignInboundSchemaTab2(this.state.containerCheck)}
                onSubmit={(values, { setSubmitting }) => {
                  this.setSubmitting = setSubmitting;
                  setSubmitting(false);
                  // if (!truck && plateImageUrl === "") {
                  //   return alert("Please select Picture of Vehicle");
                  // }
                  this.setState({ modalIsOpen: true, values });
                }}
              >
                {({ values, handleChange, handleSubmit, setValues, isSubmitting }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <h6 className="reading__title">Type of Reading</h6>
                          <div className="reading-type-list">
                            <div className="reading-type-list-items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  value="Inbound material sale"
                                  checked={selectedReadingType === "Inbound material sale"}
                                  onChange={this.handleRadioChange}
                                />
                                <span className="checkmark"></span>
                                Inbound material sale
                              </label>
                            </div>
                            <div className="reading-type-list-items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  value="Outbound material sale"
                                  checked={selectedReadingType === "Outbound material sale"}
                                  onChange={this.handleRadioChange}
                                />
                                <span className="checkmark"></span>
                                Outbound material sale
                              </label>
                            </div>
                          </div>
                          {(user?.permissions?.permissionOptions?.createCustomer === true) &&
                          <p className="new__customer-text">
                            New Customer? <span onClick={this.navigate}>Click Here to Create a New Company</span>
                          </p>
                          }
                          <div
                            className="reading__select-list"
                          >
                            <p className="new__customer-text">
                              <span >Search by company or driver</span>
                              {/* Driver, or Truck License Plate */}
                            </p>
                            <div className="form-group material-textfield">
                             <DebounceInput
                                type="text"
                                className="form-control material-textfield-input"
                                onChange={ this.handleOverAllSearch}
                                value={this.state.SearchText}
                                required
                                minLength={3}
                                debounceTimeout={500}
                                />
                              <label className="material-textfield-label">Start searching...</label>
                            </div>
                            <ul className={showSearch === false ?"select_driver-list select__truck-list box-shadow":"select_driver-list select__truck-list box-shadow mb-3"}>
                              {showSearch === true ? overAllData && overAllData.map((t,index) => {
                                return (
                                  <>
                                  <Spin indicator={antIcon} spinning={this.state.isLoading}>
                                    {
                                     data_type === 'customer' &&
                                      <li
                                      className={String(selectedCustomerSearch && selectedCustomerSearch._id) === String(t._id) ? "active" : ""}
                                      onClick={this.selectCustomerSearch.bind(this, t)}
                                        key={index}
                                      >
                                        <div className="d-flex flex-column">
                                          <div className="driver__name">
                                          <span>{t.displayName}</span>
                                          </div>
                                        </div>
                                            <label className="custom-checkbox custom-checkbox-line radio__checkbox custom-radio-radius" style={{paddingTop: "10px",paddingBottom: "10px"}}>
                                          <input
                                            type="checkbox"
                                            className="custom-checkbox--input"
                                            onChange={this.selectCustomerSearch.bind(this, t)}
                                            checked={String(selectedCustomerSearch && selectedCustomerSearch._id) === String(t._id) ? true : false}
                                            />
                                          <span className="checkmark"></span>
                                        </label>
                                      </li>
                                    }
                                    {t.drivers.length > 0 ? (
                                      t.drivers.map((driver, index) => {
                                        const driverName = `${driver?.firstName} ${driver?.lastName}`;
                                        const truckItems = t?.trucks
                                          .filter((truck) => truck?.customerId === driver?.customerId && (truck?.plateNumber || truck?.number !== ""))
                                          .map((truck, truckIndex) => (
                                            <li
                                              className={
                                                String(selectedTruckSearch && selectedTruckSearch._id) ===
                                                  String(truck._id)
                                                  ? "active"
                                                  : ""
                                              }
                                              onClick={this.selectedDriverSearch.bind(this, t, driver,truck)}
                                              key={`${index}-${truckIndex}`}
                                            >
                                              <div className="d-flex flex-column">
                                                <div className="driver__name">
                                                 <span>{t.displayName}</span>
                                                 <br />
                                                  <small>{driverName} | {truck?.plateNumber}</small>
                                                </div>
                                              </div>
                                              <label className="custom-checkbox custom-checkbox-line radio__checkbox custom-radio-radius"  style={{paddingTop: "10px",paddingBottom: "10px"}}>
                                                <input
                                                  type="checkbox"
                                                  className="custom-checkbox--input"
                                                  onChange={this.selectedDriverSearch.bind(this, t, driver,truck)}
                                                  checked={
                                                    String(selectedTruckSearch && selectedTruckSearch._id) ===
                                                      String(truck._id)
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </li>
                                          ));

                                        return truckItems;
                                      })
                                    ) : null}
                                    </Spin>
                                  </>
                                )}
                              ) : null}
                              
                            </ul>
                                   
                            {/* <ErrorMessage component="span" name="customer" className="invalid-feedback d-block" /> */}
                          </div>
                        
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div
                              className="reading__select-list"
                              onClick={() =>
                                this.setState({
                                  isCreateDrawerOpen: true,
                                  isCompany: true,
                                  isDriver: false,
                                  isProjectDrawerOpen:false,
                                  isTruck: false,
                                  createDriverSidebar: false,
                                  createDriverForm: false
                                })
                              }
                            >
                              <div className="reading__select-item" onClick={this.showDrawer}>
                                <h6 className="reading__select-title">Select Company </h6>
                                <p className="reading__select-sub-title">
                                  <input
                                    name="customer"
                                    readOnly
                                    placeholder="Select"
                                    className="reading__select-input"
                                    value={values.customer}
                                    type="text"
                                  />
                                </p>
                              </div>
                              <ErrorMessage component="span" name="customer" className="invalid-feedback d-block" />
                            </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            className="reading__select-list"
                            onClick={() =>
                              this.setState({
                                isCreateDrawerOpen: true,
                                isCompany: false,
                                isDriver: false,
                                isProjectDrawerOpen:true,
                                isTruck: false,
                                createDriverSidebar: false,
                                createDriverForm: false
                              })
                            }
                          >
                            <div className="reading__select-item" onClick={this.showDrawer}>
                              <h6 className="reading__select-title">Select Project</h6>
                              <p className="reading__select-sub-title">
                                <input
                                  name="driver"
                                  readOnly
                                  placeholder="Select"
                                  className="reading__select-input"
                                  value={values.project}
                                  type="text"
                                />
                              </p>
                            </div>
                            <ErrorMessage component="span" name="driver" className="invalid-feedback d-block" />
                          </div>
                          {/* {_.get(driver, "licenseImageUrl") && (
                            <div className="select__driver-img">
                              <img
                                className="img-fluid"
                                src={driver.licenseImageUrl}
                                style={{ transform: `rotate(${driver.rotation}deg)` }}
                                alt=""
                              />
                            </div>
                          )} */}
                        </div>
                        <div className="col-md-4">
                          <div
                            className="reading__select-list"
                            onClick={() =>
                              this.setState({
                                isCreateDrawerOpen: true,
                                isCompany: false,
                                isProjectDrawerOpen:false,
                                isDriver: true,
                                isTruck: false,
                                createDriverSidebar: false,
                                createDriverForm: false
                              })
                            }
                          >
                            <div className="reading__select-item" onClick={this.showDrawer}>
                              <h6 className="reading__select-title">Select Driver</h6>
                              <p className="reading__select-sub-title">
                                <input
                                  name="driver"
                                  readOnly
                                  placeholder="Select"
                                  className="reading__select-input"
                                  value={values.driver}
                                  type="text"
                                />
                              </p>
                            </div>
                            <ErrorMessage component="span" name="driver" className="invalid-feedback d-block" />
                          </div>
                          {_.get(driver, "licenseImageUrl") && (
                            <div className="select__driver-img">
                              <img
                                className="img-fluid"
                                src={driver.licenseImageUrl}
                                style={{ transform: `rotate(${driver.rotation}deg)` }}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="custome__tabs">
                            <ul className="custome__tabs-list">
                              <li className={truckTab === 1 ? "active" : ""} onClick={this.setTruckTab.bind(this, 1)}>
                                Truck has been here
                              </li>
                              <li className={truckTab === 2 ? "active" : ""} onClick={this.setTruckTab.bind(this, 2)}>
                                Truck has never been here
                              </li>
                            </ul>
                          </div>
                          {truckTab === 1 ? (
                            <div className="truck__stay-details active">
                              <div
                                className="reading__select-list mb-20"
                                onClick={() =>
                                  this.setState({
                                    isCreateDrawerOpen: true,
                                    isTruck: true,
                                    isCompany: false,
                                    isDriver: false,
                                    createDriverSidebar: false,
                                    isProjectDrawerOpen:false,
                                    createDriverForm: false
                                  })
                                }
                              >
                                <div className="reading__select-item" onClick={this.showDrawer}>
                                  <h6 className="select__driver-title">
                                    Select Truck {customer && `from ${customer.displayName?.toLowerCase()}`}
                                  </h6>
                                  <p className="reading__select-sub-title">
                                    <input
                                      name="truck"
                                      readOnly
                                      placeholder="Select"
                                      className="reading__select-input"
                                      value={selectedTruck ? selectedTruck?.number || selectedTruck?.plateNumber : selectedTruckSearch ? selectedTruckSearch?.number || selectedTruckSearch?.plateNumber:""}
                                      type="text"
                                    />
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                {this.state.truck?.containers?.length > 1 && <div className="col-md-6 top-margin-10">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      // required
                                      // value={values.type}
                                      onChange={(e)=> this.changeContainer(e.target.value,setValues,values,true)}
                                      // name="type"
                                    >
                                      {this.state.truck.containers.map((v,i)=> <option key={i} value={v.size}>{v.size}</option>)}
                                    </select>
                                    <label className="material-textfield-label">
                                      Containers
                                    </label>
                                  </div>
                                </div>}
                                <div className={`${this.state.truck?.containers?.length > 1 ? "col-md-6" : "col-md-12"} top-margin-10`}>
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"input"}
                                      type="text"
                                      className="form-control material-textfield-input"
                                      onValueChange={_values => {
                                        const { formattedValue } = _values;
                                        const defaultOutboundWeight = formattedValue;
                                        setValues({
                                          ...values,
                                          defaultOutboundWeight
                                        });
                                        this.setState({
                                          initialValues: { ...this.state.initialValues, defaultOutboundWeight }
                                        });
                                      }}
                                      value={values.defaultOutboundWeight}
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="defaultOutboundWeight"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">Outbound Truck Weight</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="truck__stay-details active">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      name="plateNumber"
                                      type="text"
                                      className="form-control material-textfield-input"
                                      required
                                      value={values?.plateNumber ? values?.plateNumber : ""}
                                      onChange={e => this.setValues(e, values, setValues)}
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="plateNumber"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Truck License Plate #<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      required
                                      value={values.type}
                                      onChange={e => this.setValues(e, values, setValues)}
                                      name="type"
                                    >
                                  {
                                   truckTypes?.map((item)=>{
                                   return(
                                     <option value={item.value}>{item.name}</option>
                                   )
                                   })
                                  }
                                    </select>
                                    <label className="material-textfield-label">
                                      Truck Type<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      name="number"
                                      type="text"
                                      className="form-control material-textfield-input"
                                      required
                                      value={values.number ? values.number : ""}
                                      onChange={e => this.setValues(e, values, setValues)}
                                    />
                                    <ErrorMessage component="span" name="number" className="invalid-feedback d-block" />
                                    <label className="material-textfield-label">
                                      Truck Number
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div>
                        <label className={`${values.containerCheck ? "mb-3" :""} custom-checkbox custom-checkbox-line`}>
                              <input
                               checked={values?.containerCheck}
                               onChange={(e) => {
                                const {checked,name} = e.target
                                setValues({...values,[name]:checked})
                                this.setState({containerCheck:checked,requiredFields: checked ? [...this.state.requiredFields] : this.state.requiredFields.filter(v => v !== "defaultOutboundWeight" && v !== "containers")})
                                }}
                                name="containerCheck"
                                type="checkbox"
                                className="custom-checkbox--input"
                              />
                              <span className="checkmark"></span>
                              <span className="text-decoration-none">Add default container weight</span>
                            </label>
                      </div>
                              {values.containerCheck ?<div className="row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      // required
                                      // value={values.type}
                                      onChange={(e)=> this.changeContainer(e.target.value,setValues,values)}
                                      // name="type"
                                    >
                                    <option value="">Select Container</option>
                                      {this.state.allContainers?.map((v,i)=> {
                                        const disabled = this.checkAlready(v.size)
                                       return <option disabled={disabled} selected={v.size === this.state.containers.size} key={i} value={v.size}>{v.size}</option>})}
                                    </select>
                                    <label className="material-textfield-label">
                                      Containers<span>*</span>
                                    </label>
                                  <ErrorMessage
                                      component="span"
                                      name="containers"
                                      className="invalid-feedback d-block"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"input"}
                                      type="text"
                                      className="form-control material-textfield-input"
                                      onValueChange={_values => {
                                        const { formattedValue , floatValue } = _values;
                                        const defaultOutboundWeight = formattedValue;
                                        const container = this.state.containers;
                                        if(container?.size) container.defaultWeight = floatValue;
                                        setValues({
                                          ...values,
                                          defaultOutboundWeight,
                                          containers:container
                                        });
                                        this.setState({
                                          initialValues: { ...this.state.initialValues, defaultOutboundWeight },
                                          containers:container
                                        });
                                      }}
                                      value={values.defaultOutboundWeight}
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="defaultOutboundWeight"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Set Default Outbound Truck Weight<span>*</span>
                                    </label>
                                  </div>
                                </div>
                              </div>:""}
                              <div className="row">
                                <div className="col-md-6 top-margin-10">
                                  {!isCameraOn ? (
                                    <div className="truck__img">
                                      {plateImageUrl !== "" ? <img src={plateImageUrl} alt="" /> : ""}
                                    </div>
                                  ) : (
                                    <div className="truck__img">
                                      <Camera
                                        onTakePhoto={dataUri =>
                                          this.onTakePhoto(dataUri, "plateImageUrl", "isCameraOn")
                                        }
                                        imageType={IMAGE_TYPES.PNG}
                                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                                        isMaxResolution={true}
                                        isImageMirror={false}
                                        isSilentMode={false}
                                        isDisplayStartCameraError={true}
                                        isFullscreen={false}
                                      />
                                      {/* <ReactWebcam
                                        onTakePhoto={dataUri =>
                                          this.onTakePhoto(dataUri, "plateImageUrl", "isCameraOn")
                                        }
                                      /> */}
                                    </div>
                                  )}
                                  {!isCameraOn && (
                                    <button
                                      className="btn btn-lg w-100 take__picture-btn"
                                      type="button"
                                      onClick={() => {
                                        this.setState({
                                          isCameraOn: true,
                                          plateImageUrl: ""
                                        });
                                      }}
                                    >
                                      <TakePictureIcon color="#20456D" />
                                      Take Picture of Vehicle
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-6 top-margin-10">
                                  {!isCameraOnLoad ? (
                                    <div className="truck__img">
                                      {loadImageUrl !== "" ? <img src={loadImageUrl} alt="" /> : ""}
                                    </div>
                                  ) : (
                                    <div className="truck__img">
                                      <Camera
                                        onTakePhoto={dataUri =>
                                          this.onTakePhoto(dataUri, "loadImageUrl", "isCameraOnLoad")
                                        }
                                        imageType={IMAGE_TYPES.PNG}
                                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                                        isMaxResolution={true}
                                        isImageMirror={false}
                                        isSilentMode={false}
                                        isDisplayStartCameraError={true}
                                        isFullscreen={false}
                                      />
                                      {/* <ReactWebcam
                                        onTakePhoto={dataUri =>
                                          this.onTakePhoto(dataUri, "loadImageUrl", "isCameraOnLoad")
                                        }
                                      /> */}
                                    </div>
                                  )}
                                  {!isCameraOnLoad && (
                                    <button
                                      className="btn btn-lg w-100 take__picture-btn"
                                      type="button"
                                      onClick={() => {
                                        this.setState({
                                          isCameraOnLoad: true,
                                          loadImageUrl: ""
                                        });
                                      }}
                                    >
                                      <TakePictureIcon color="#20456D" />
                                      Take Picture of Load
                                    </button>
                                  )}
                                </div>
                              </div>
                              {(plateImageUrl !== "" || loadImageUrl !== "") && (
                                <div className="row  d-flex justify-content-start align-items-center">
                                  <div className="col-md-6">
                                    {plateImageUrl !== "" ? (
                                      <React.Fragment>
                                        <div className="confirm__license">
                                          <h3>Confirm Truck’s License Plate Picture.</h3>
                                          <p>Make sure this picture is clear!</p>
                                          <p>If it is not, click on the “Take Picture” button again.</p>
                                        </div>
                                        <div className="truck__img truck__img_pad numberplate">
                                          <img src={plateImageUrl} alt="" />
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-md-6">
                                    {loadImageUrl !== "" ? (
                                      <React.Fragment>
                                        <div className="confirm__license">
                                          <h3>Confirm Load Photo is clear.</h3>
                                          <p>Make sure this picture is clear!</p>
                                          <p>If it is not, click on the “Take Picture” button again.</p>
                                        </div>
                                        <div className="truck__img truck__img_pad numberplate">
                                          <img src={loadImageUrl} alt="" />
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {truckTab === 1 && (
                        <div className="row">
                          <div className="col-md-12">
                            {!isCameraOnLoad ? (
                              <div className="truck__img">
                                {loadImageUrl !== "" ? <img src={loadImageUrl} alt="" /> : ""}
                              </div>
                            ) : (
                              <div className="truck__img">
                                <Camera
                                  onTakePhoto={dataUri => this.onTakePhoto(dataUri, "loadImageUrl", "isCameraOnLoad")}
                                  imageType={IMAGE_TYPES.PNG}
                                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                                  isMaxResolution={true}
                                  isImageMirror={false}
                                  isSilentMode={false}
                                  isDisplayStartCameraError={true}
                                  isFullscreen={false}
                                />
                              </div>
                            )}
                            <button
                              className="btn btn-lg w-100 take__picture-btn"
                              type="button"
                              onClick={() =>
                                this.setState({
                                  isCameraOnLoad: true,
                                  plateImageUrl: ""
                                })
                              }
                            >
                              <TakePictureIcon color="#20456D" />
                              Take Picture of Load
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="form-group material-textfield mt-4">
                        <select
                          value={this.state.dumpRateType}
                          name="dumpRate"
                          className="form-control custom-select w-150 material-textfield-input"
                          onChange={(e) =>
                            this.onChangeDumpRate(e, values, setValues)
                          }
                        >
                          <option value="">Select Dump Rate</option>
                          {Object.keys(
                            this.state.pricingTierDumpRates
                          ).map((key) => {
                            if (
                              key !== "defaultOption" &&
                              key !== "capoutPrice" &&
                              key !== "minimumPrice" &&
                              (this.state?.pricingTierDumpRates[key] ?? '').toString().length > 0
                            ) {
                              return (
                                <option key={key} value={key}>
                                  {customStartCase(key)}
                                </option>
                              );
                            }
                            return null;
                          })}
                        </select>
                        <ErrorMessage
                          component="span"
                          name="dumpRate"
                          className="invalid-feedback d-block"
                        />
                        <label className="material-textfield-label">
                          Dump Rate
                        </label>
                      </div>

                      <button
                        type="submit"
                        disabled={requiredFields.some(key =>  !values[key] || Object.keys(values[key])?.length < 1)}
                        className="btn btn-dark btn-lg w-100 create__order-btn"
                      >
                        Create Order {customer && `for ${customer.displayName?.toLowerCase()}`}
                      </button>
                    </div>
                    <ReactModal
                      ariaHideApp={false}
                      isOpen={this.state.modalIsOpen}
                      onRequestClose={this.closeModal}
                      contentLabel="Please Confirm"
                    >
                      <div className="react-modal-dialog react-modal-dialog-centered driver__confirm-modal">
                        <div className="react-modal-header">
                          <h5 className="react-modal-title">Please Confirm</h5>
                          <button type="button" className="btn react-modal-close" onClick={this.closeModal}>
                            <CloseIcon />
                          </button>
                        </div>
                        <div className="react-modal-body">
                          <p>Please make sure the following information is correct</p>
                          <div className="company__driver-title">
                            <h6 className="title">Company Name</h6>
                            <p className="sub__title">
                              {customer && customer.displayName?.toLowerCase()}
                            </p>
                          </div>
                          <div className="company__driver-title">
                            <h6 className="title">Company ID</h6>
                            <p className="sub__title">{customer && customer.customerId}</p>
                          </div>
                          <div className="company__driver-title">
                            <h6 className="title">Company Address</h6>
                            <p className="sub__title">
                              {customer && customer.address
                                ? `${customer.address} ${customer.city} ${customer.state}, ${customer.zip}`
                                : "N/A"}
                            </p>
                          </div>
                          <button
                            disabled={isSubmitting}
                            onClick={this.handleSubmit}
                            className="btn btn-dark btn-lg w-100 btn-update"
                          >
                            {isSubmitting ? "Please wait..." : "Yes, this is Correct!"}
                          </button>
                        </div>
                      </div>
                    </ReactModal>
                  </form>
                )}
              </Formik>
            </div>
            <Drawer
              title="Basic Drawer"
              placement="right"
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
              className="select__driver-drawer creat__order-drawer"
            >
              {isCompany ? (
                <div className="creat__order-drawer-inner">
                  <div className="head-section-header d-flex align-items-center justify-content-between">
                    <div className="head-section-title">Select Company</div>
                    <div className="head-section-cta">
                    <div onClick={this.onClose} className="btn- btn-link- btn-back- mobpad- btn btn-link btn-close pr-0">
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form-group material-textfield">
                    <DebounceInput
                      type="text"
                      className="form-control"
                      onChange={this.handleCompanySearch}
                      value={this.state.companySearchText}
                      minLength={3}
                      debounceTimeout={500}
                      required
                    />
                    <label className="material-textfield-label">Search Company</label>
                  </div>
                  <ul className="select_driver-list box-shadow">
                  {this.state.isLoading &&   
                    <Spin  className="select_driver-list-spiner" indicator={antIcon} spinning={this.state.isLoading}></Spin>
                    }
                    {customers.map(customer => {
                      return (
                        <li
                          className={
                            String(selectedCustomer && selectedCustomer._id) === String(customer._id) ? "active" : ""
                          }
                          key={customer._id}
                        >
                          <div className=" filter_list-inner d-flex align-items-center">
                            <p className="driver__name">{_.get(customer, "displayName", "")}</p>
                          </div>
                          <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                            <input
                              type="checkbox"
                              className="custom-checkbox--input"
                              onChange={this.selectCustomer.bind(this, customer)}
                              checked={
                                String(selectedCustomer && selectedCustomer._id) === String(customer._id) ? true : false
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="mt-20 mb-20">
                    {customers.length > 0 ? (
                      <button
                        className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
                        onClick={this.assignCustomer.bind(this)}
                      >
                        Assign Company
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {isDriver && (
                <div className="creat__order-drawer-inner">
                  {
                    <>
                      <div className="head-section-header d-flex align-items-center justify-content-between">
                        <div className="head-section-title">Select Driver</div>
                        <div className="head-section-cta">
                          {customer && (
                            <button
                              className="btn btn-dark btn-sm smbtn"
                              onClick={() =>
                                this.setState({ isLicenseCamera: true, createDriverSidebar: true, isDriver: false })
                              }
                            >
                              Create Driver
                            </button>
                          )}
                          <div onClick={this.onClose} className="btn btn-link btn-back mobpad">
                            <CloseIcon />
                          </div>
                        </div>
                      </div>
                      <div className="form-group material-textfield">
                        <DebounceInput
                          type="text"
                          className="form-control material-textfield-input"
                          onChange={this.handleDriverSearch}
                          value={this.state.driverSearchText}
                          required
                          minLength={3}
                          debounceTimeout={500}
                        />
                        <label className="material-textfield-label">Search Driver</label>
                      </div>
                      <ul className="select_driver-list box-shadow">
                        {drivers.map(d => {
                          return (
                            <li
                              className={String(selectedDriver && selectedDriver._id) === String(d._id) ? "active" : ""}
                              key={d._id}
                            >
                              <div className="filter_list-inner d-flex align-items-center">
                                <div className="thumb-preview">
                                  <img
                                    style={{ transform: `rotate(${d.rotation}deg)` }}
                                    src={d.licenseImageUrl}
                                    alt=""
                                  />
                                </div>
                                <p className="driver__name">
                                  {d.firstName} {d.lastName}
                                </p>
                              </div>
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  onChange={this.selectDriver.bind(this, d)}
                                  checked={
                                    String(selectedDriver && selectedDriver._id) === String(d._id) ? true : false
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                      <div className="mt-20 mb-20">
                        {drivers.length > 0 ? (
                          <button
                            className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
                            onClick={this.assignDriver.bind(this)}
                          >
                            Assign Driver
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  }
                </div>
              )}
              { this.state.isProjectDrawerOpen && 
                <div className="creat__order-drawer-inner">
                { !this.state.createProjectSideBar ?
                  <>
                    <div className="head-section-header d-flex align-items-center justify-content-between">
                      <div className="head-section-title">Select Project</div>
                      <div className="head-section-cta">
                        {customer && (
                          <button
                            className="btn btn-dark btn-sm smbtn"
                            onClick={() =>
                              this.setState({ isLicenseCamera:false,createProjectSideBar:true, createDriverSidebar: false, isDriver: false })
                            }
                          >
                            Create Project
                          </button>
                        )}
                        <div onClick={this.onClose} className="btn btn-link btn-close pr-0">
                          <CloseIcon />
                        </div>
                      </div>
                    </div>
                    <div className="form-group material-textfield">
                      <DebounceInput
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={this.handleProjectSearch}
                        value={this.state.projectSearchText}
                        required
                        minLength={3}
                        debounceTimeout={500}
                      />
                      <label className="material-textfield-label">Search Project</label>
                    </div>
                    <ul className="select_driver-list box-shadow">
                    <Spin indicator={antIcon} spinning={this.state.projectSpinning}>
                      {this.state.projectsData?.length > 0 && this.state.projectsData.map(d => {
                        return (
                          <>
                            {d.projectStatus ==="active" &&
                              <li
                                className={String(this.state.selectedProject && this.state.selectedProject._id) === String(d._id) ? "active" : ""}
                                key={d._id}
                              >
                                <div className="filter_list-inner d-flex align-items-center">
                                  {/* <div className="thumb-preview">
                                    <img
                                      style={{ transform: `rotate(${d.rotation}deg)` }}
                                      src={d.licenseImageUrl}
                                      alt=""
                                    />
                                  </div> */}
                                  <p className="driver__name">
                                    {d.projectName}
                                  </p>
                                </div>
                                <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-checkbox--input"
                                    onChange={this.selectedProject.bind(this, d)}
                                    checked={
                                      String(this.state.selectedProject && this.state.selectedProject._id) === String(d._id) ? true : false
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                            }
                          </>
                        );
                      })}
                      </Spin>
                    </ul>
                    <div className="mt-20 mb-20">
                      {this.state.projectsData?.length > 0 ? (
                        <button
                          className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
                          onClick={this.assignProjects.bind(this)}
                        >
                          Assign Project
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                  : 
                  <>
                  <CreateProject 
                   customerData={this.state.customer}
                   onClose={() =>
                    this.setState({
                      isCreateDrawerOpen: false,
                      isTruck: false,
                      isCompany: false,
                      isDriver: false,
                      createDriverSidebar: false,
                      createDriverForm: false,
                      rotation: 0,
                      licenseImageUrl: "",
                      createProjectSideBar:false,
                      isLicenseCamera: false
                    },()=>{
                      this.fetchProject()
                    })}
                    {...this.props}
                  />
                  </>
                }
              </div>
              }
              {createDriverForm && (
                <Formik
                  enableReinitialize
                  initialValues={driverInitialValues}
                  validationSchema={driverSchema}
                  onSubmit={(values, formikProps) => this.submitDriverForm(values, formikProps)}
                >
                  {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} noValidate style={{ display: false ? "none" : "" }}>
                      <CreateDriver
                        {...this.props}
                        isSubmitting={isSubmitting}
                        values={values}
                        handleChange={handleChange}
                        onTakePhoto={dataUri => this.onTakePhoto(dataUri, "licenseImageUrl", "isLicenseCamera")}
                        licenseImageUrl={this.state.licenseImageUrl}
                        isLicenseCamera={this.state.isLicenseCamera}
                        onClose={() =>
                          this.setState({
                            isCreateDrawerOpen: true,
                            isTruck: false,
                            isCompany: false,
                            isDriver: false,
                            createDriverSidebar: true,
                            createDriverForm: false,
                            rotation: 0,
                            licenseImageUrl: "",
                            isLicenseCamera: true
                          })
                        }
                        rotation={rotation}
                        setRotation={() => this.setState({ rotation: rotation + 45 })}
                        onCrop={img => this.onCropImage(img)}
                      />
                    </form>
                  )}
                </Formik>
              )}
              {createDriverSidebar && (
                <NewDriver
                  onClose={() =>
                    this.setState({
                      isCreateDrawerOpen: true,
                      isTruck: false,
                      isCompany: false,
                      isDriver: true,
                      createDriverSidebar: false,
                      rotation: 0
                    })
                  }
                  setInitialState={({ state }) => this.setInitialState({ state })}
                  user={user}
                  driverDetails={this.props.driverDetails}
      
                />
              )}
              {isTruck ? (
                <div className="creat__order-drawer-inner">
                  <div className="head-section-header d-flex align-items-center justify-content-between">
                    <div className="head-section-title">Select Truck for {customer && customer.displayName}</div>
                    <div className="head-section-cta">
                      <div onClick={this.onClose} className="btn btn-link btn-back">
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control material-textfield-input"
                      onChange={this.handleTruckSearch}
                      value={this.state.truckSearchText}
                      required
                    />
                    <label className="material-textfield-label">Select Truck</label>
                  </div>
                  <ul className="select_driver-list select__truck-list box-shadow">
                    {trucks.map(t => {
                      return (
                        <li
                          className={String(selectedTruck && selectedTruck._id) === String(t._id) ? "active" : ""}
                          key={t._id}
                        >
                          <div className="d-flex flex-column">
                            <div className="driver__name">
                              <span>License plate number:</span>
                              {t.plateNumber}
                            </div>
                            <div className="driver__name">
                              <span>Truck Number:</span>
                              {t.number}
                            </div>
                          </div>
                          <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                            <input
                              type="checkbox"
                              className="custom-checkbox--input"
                              onChange={this.selectTruck.bind(this, t)}
                              checked={String(selectedTruck && selectedTruck._id) === String(t._id) ? true : false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="mt-20 mb-20">
                    {trucks.length > 0 ? (
                      <button
                        className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn "
                        onClick={this.assignTruck.bind(this)}
                      >
                        Assign Truck
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                </div>
              ) : (
                ""
              )}
            </Drawer>
            {/*Order Filters End*/}
            {this.state.isCreateCustomerModelOpen &&
            <CreateCustomer
            createCustomerModelOpen={this.state.isCreateCustomerModelOpen}
            closeModel={this.closeModel}
            assignNewCustomer={this.assignNewCustomer}
            />}
          </div>
        </div>
      </div>
    );
  }
}
