

import React, { PureComponent } from "react";
import "../styles.scss";
import SignatureCanvas from "react-signature-canvas";
// import Amex from "../../../images/amex.svg";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import visa2 from "../../../images/visa_2.svg";
import masterCard from "../../../images/MasterCard_Logo.svg";
import amex from "../../../images/amex.svg";
import americanexpress from "../../../images/americanexpress.svg";
import maestro from  "../../../images/maestro_card.svg";
import dinersclub from "../../../images/dinersclub.svg";
import closeRed from "../../../images/close_red.svg";
import NumberFormat from "react-number-format";
import AddCardComponent from "../../payabli/addNewCard";
// import AddTerminalComponent from "../../payabli/addNewTerminal";
import _ from "lodash";
import PayEngineCardModal from "../../PendingOrderDetails/partials/credit-card-payengine-modal";
import SignaturePad from "../../SignaturePad/container";
import { isRoundOff, numberFormat } from "../../../constants/common";
import { responseCode } from "../../../utils/responseCode";
// import CryptoJS from "crypto-js";
export default class PaymentOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.order,
      activeTab: this.props?.activeTabState ,
      SignatureCanvas: "",
      customerCashAmout: 0,
      customerCashValue: 0,
      customerPaymentTerms: "",
      saveSignature: "",
      isFrontCheckCameraOn: false,
      isBackCheckCameraOn: false,
      frontCheckImage: "",
      backCheckImage: "",
      checkNumber: "",
      isFrontCameraUse: false,
      isTerminalPayment: false,
      isCard: true,
      customerData: {},
      customerPayabliData: {
        StoredMethods: [],
      },
      isAddNewCard: false,
      isTerminal: false,
      paymentStatus: false,
      cardId: "",
      reRender: false,
      devices: [],
      selectedDevice: "",
      terminalStatus: "",
      signatureUrl: this.props?.signatureUrl ?? "",
      payMethod: "",
      selectedSplitPaymentMethods: [],
      splitPayments: this.props.parentComponentState.splitPayments,
      remaingSplitAmount: null,
      totalCardAmountWithFee: {},
      totalTerminalAmountWithFee: {},
      selectedCreditMemos: [],
      remainingCreditAmount: 0,
      paymentProgress: false,
      isTerminalAvaliable:false,
      isChargeDevice:false,
      cardImageData: {
        visa: visa2,
        mastercard: masterCard,
        amex: americanexpress,
        maestro: maestro,
        americanexpress: amex,
        dinersclub: dinersclub
      }
    };
    this.creditMemoRef = React.createRef();
  }
  onTakePhoto = async (dataURI, Ischeck, image) => {
    if (Ischeck === "frontCheck") {
      this.setState({
        frontCheckImage: dataURI,
        isFrontCheckCameraOn: false,
        isFrontCameraUse: false,
      });
      this.props.checkNumber(dataURI, "frontCheckImage");
    } else {
      this.setState({ backCheckImage: dataURI, isBackCheckCameraOn: false });
      this.props.checkNumber(dataURI, "backCheckImage");
    }
  };

  componentDidMount = async () => {
    const payment__terms = this.props.customerPaymentTerms;
    if (payment__terms) {
      this.setState({
        customerPaymentTerms: payment__terms,
        customerData: this.props.customerData,
      });
      // this.getCustomerPayment()
      //this.assignCardData(this.props.customerData)
      this.setState({ customerPayabliData: this.props.customerPayabliData });
      if (this.signatureCanvasRef) {
        this.signatureCanvasRef.fromDataURL(this.state?.signatureUrl);
      }
    }
    this.fetchTerminalDevices()
    document.addEventListener('click', this.handleOutsideClick);
  };

  componentDidUpdate = async (prevProps) => {
    this.setState({ customerPayabliData: this.props.customerPayabliData, selectedSplitPaymentMethods: this.props.parentComponentState.selectedSplitPaymentMethods,activeTab:this.props.activeTabState === 'credit-card' ? "creditCard" : this.props.activeTabState });
    if (prevProps.customerData._id !== this.props.customerData._id) {
      const payment__terms = this.props.customerPaymentTerms;
      this.props.assignCard(
        "",
        ""
      );
      this.setState({ cardId: "", payMethod: "" })

      if (payment__terms === "cod") {
        // this.props.isTerminalTabActive(false)
        if (this.props.paymentType === "terminal") {
          this.props.paymentMethod("creditcard");
          this.props.activeTab("creditCard", true);
          this.setState({
            isCard: false,
            isTerminalPayment: true,
            activeTab: "creditCard"

          }, () => {
            this.fetchTerminalDevices()
            // this.props.isTerminalTabActive(true)
          });
        }
        if (this.props.paymentType === "credit-card") {
          this.props.paymentMethod("creditcard");
          this.setState({ activeTab: "creditCard" });
          this.props.activeTab("creditCard", false);
        }
        if (this.props.paymentType === "check") {
          this.setState({ activeTab: "check" })
          this.props.paymentMethod("check");
          this.props.activeTab("check", false);
        }
        if (this.props.paymentType === "cash" || this.props.paymentType === "") {
          this.props.paymentMethod("cod");
          this.setState({ activeTab: "cash" });
          this.props.activeTab("cash", false);
        }
        // if (_.get(this.props, "customerData.paymentType", false)) {
        //   this.setState({ selectedSplitPaymentMethods: [this.props.customerData.paymentType] })
        // }
      }

    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.activeTabState !== nextProps.activeTabState) {
      // this.props.isTerminalTabActive(nextProps.activeTabState === 'creditCard' ? this.state.isTerminalPayment : false)
    }
    this.setState({ customerData: nextProps.customerData, validationError: false });
  }

  //get payabli data
  getCustomerPayment = async () => {
    this.setState({ customerPayabliData: this.props.customerPayabliData });
  };
  
  SaveSignature = async () => {
    this.setState({ saveSignature: this.state.SignatureCanvas.toDataURL() });
    this.props.signatureData(this.state.SignatureCanvas.toDataURL());
  };

  onChangeFile = async (e, CheckImage) => {
    // const file = e.target.files[0];
    const imageShow = URL.createObjectURL(e.target.files[0]);
    this.getBase64(e.target.files[0], (result) => {
      if (CheckImage === "frontCheck") {
        this.setState({ frontCheckImage: imageShow });
        this.props.checkNumber(result, "frontCheckImage");
      } else {
        this.setState({ backCheckImage: imageShow });
        this.props.checkNumber(result, "backCheckImage");
      }
    });
  };
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  numberWithCommas = (x) => {
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };
  numberWithCommasAndRound = (x) => {
    const roundedValue = Math.round(x);
    var parts = roundedValue.toFixed(0).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };

  insertSlash(val) {
    return val.replace(/^(\d{2})(\d{2})/, "$1/$2");
  }
  assignCardData = async (cardData) => {
    this.setState({ payabliInfo: cardData, isAddNewCard: false });
    const payload = {
      _id: this.state.customerData._id,
      payabliCustomer: cardData,
    };
    await this.props.updateCustomer(payload);
    this.props.getCustomerPayment(cardData?.customerId);
    this.setState({ cardAdded: false });
  };

  paymentStatus = () => {
    this.setState({ paymentStatus: true });
  };
  //get payabli data


  fetchTerminalDevices = async () => {
    if (this.props.paybliEnable) {
      const {
        value: { status, data },
      } = await this.props.getPaybliTerminalDevices({
        entryPoint: this.props.paybliDetails.entry_point,
      }); // get paybli devices
      if (status) {
        // this.setState({ paybliEnable: true });
        this.setState({
          buttonText: "",
          selectPaymentMethod: "terminal",
          enableTerminalButton: true,
          devices: data?.responseList,
          isTerminalAvaliable: data?.responseList.length > 0 ? true :false,
          selectedDevice:
            data?.responseList.length === 1 ? data?.responseList[0]["deviceId"] : "",
          connectionStatus: "Connected",
        });
      }
    }
    else {
      const { value } = await this.props.fetchDevices();
      if (value.status) {
        this.setState({
          selectPaymentMethod: "terminal",
          enableTerminalButton: true,
          devices: value.data,
          isTerminalAvaliable:value.data.length>0? true :false,
          selectedDevice:
            value.data.length === 1 ? value.data[0]["deviceId"] : "",
          // connectionStatus: "Connected",
        }, () => {
          this.fetchTerminalDeviceStatus();
        });
      } else {
        this.setState({
          selectPaymentMethod: "terminal",
          enableTerminalButton: false,
          connectionStatus: value.message,
        });
      }
    }
  }

  setAsyncState = (newState) => new Promise((resolve) => this.setState(newState, resolve));

  async chargeOnDevice() {
    if (this.props.SplitPaymentValidation("terminal", true, this.state.splitPayments)) {
      try {
        this.props.setErrorMessage("")
        let payAmount = 0
        const SplitPayment = this.state.splitPayments.map(payment => ({ ...payment }));
        this.state.selectedSplitPaymentMethods.includes("terminal") && SplitPayment.length > 0 &&
          SplitPayment.map((payment) => {
            if (payment.paymentMethod === "terminal") {
              if (_.get(this.props.user, "company.chargeStripeCardFees", false)) {
                const striptFeeOnCreditCardAmount = this.calcFee(this.props.parentComponentState.isSplitPaymentOption ? payment.paymentAmount : this.props.parentComponentState.withOutFeeAndRoundOffTotal , "USD");
                payAmount = this.round2decimal(striptFeeOnCreditCardAmount.total)
              } else {
                payAmount = this.props.parentComponentState.isSplitPaymentOption ? payment.paymentAmount : this.props.parentComponentState.withOutFeeAndRoundOffTotal
              }
            }
            return null
          })
        const { chargeOnDevice } = this.props;
        let { selectedDevice, devices } = this.state;
        this.props.updateInProgressTerminalPayment(true)
        this.setState({ paymentStatus: false })
        if (selectedDevice === "") {
          selectedDevice = devices[0]["deviceId"];
        }
        await this.setAsyncState({
          paymentProgress: true,
          isError: false,
          errorMessage: "",
          connectionStatus: "Waiting for payment...",
        });
        // await this.setState({ isChargeCard: true });
        if (this.props.paybliEnable) {
          if(!this.props.parentComponentState.isSplitPaymentOption){
            this.props.handleCompleteOrderButtonforTerminal(true)
          }
          await this.props.createOrderForTerminal(true, true);
          this.props.terminalPaymentStatus(true)
          this.props.updateInProgressTerminalPayment(false);
          const {
            value: { data, signatureUrl, paymentHistoryData , message }
          } = await this.props.makeTerminalTransaction({
            // make payabli transaction
            cardData: {
              method: "cloud",
              device: selectedDevice,
            },
            paymentDetails: { totalAmount: payAmount },
            customerMainId: this.props.customerData._id,
            customerData: {
              customerId: this.state.customerPayabliData?.customerId,
            },
            entryPoint: this.props.paybliDetails.entry_point,
            ticketNumber:this.props.parentComponentState.ticket_number,
            orderId:this.props.order_id,
          });
          if (data?.isSuccess) {
            await this.props.terminalPaymentStatus(false)
            let splitPayment = this.state.splitPayments
            splitPayment.map((payment, index) => {
              if (payment.paymentMethod === "credit-card") {
                splitPayment[index].uniqueId = paymentHistoryData._id
              }
              return null
            })
            // setSubmitting(false);
            this.props.disablePaymentLater(true)
            this.setState({
              splitPayments: splitPayment,
              paymentProgress: false,
              isChargeDevice: true,
              connectionStatus: "Connected",
              isChargeCard: false,
              signatureUrl: signatureUrl
              // last4: data.SaleResponse.maskedCardNumber,
              // brand: data.SaleResponse.cardType,
            },()=>{ 
              if(this.props.parentComponentState.isSplitPaymentOption){
                this.props.createOrderForTerminal(true, true);
              }
            })
            this.props.handleTerminalStatus('Success', data)
             this.props.setErrorMessage("Customer charged successfully")
             setTimeout(() => {
              this.setState({ isChargeDevice:false})
            },3000)
          } else {
            // message.error("Card could not be charged, please try again.")
            this.props.updateInProgressTerminalPayment(false)
            this.props.handleTerminalStatus("Declined", data)
            this.props.updateFailedPaymentStatus(true)
            this.setState({
              isError: true,
              connectionStatus: "Connected",
              paymentProgress: false,
              isChargeDevice: false,
              isChargeCard: false,
            });
            const match = message?.match(/\b\d+\b/);
            if (match) {
              const extractedNumber = match[0];
              const matchingCode = responseCode(extractedNumber)
              if (matchingCode) {
                this.props.setErrorMessage(matchingCode.message)
              }
            }
            else if(match === null){
              this.props.setErrorMessage(message)
            }
            else{
              this.props.setErrorMessage("Card could not be charged, please try again.")
            }
          }
          // await this.setAsyncState({ isChargeCard: false, paymentProgress: false, terminalStatus: status })
        } else {
          if(!this.props.parentComponentState.isSplitPaymentOption){
            this.props.handleCompleteOrderButtonforTerminal(true)
          }
          const { selectedDevice } = this.state;
          const { value } = await this.props.fetchDeviceStatus(selectedDevice);
          if(value.status && value.data.connectionStatus.toLowerCase() !== "connected") {
            await this.setAsyncState({
              connectionStatus: value.data.connectionStatus,
              paymentProgress: false
            })
            this.props.updateInProgressTerminalPayment(false);
            return this.props.setErrorMessage("Device is not connected")
          }
          await this.props.createOrderForTerminal(true, true);
          this.props.terminalPaymentStatus(true)
          this.props.updateInProgressTerminalPayment(false)
          const {
            value: { status, data, paymentHistoryData , Code , message},
          } = await chargeOnDevice({
            amount: payAmount,
            deviceId: selectedDevice,
            customerId: this.state.customerData._id,
            orderId: this.props.order_id,
            // description: timestamp
          });
          if (status) {
            await this.props.terminalPaymentStatus(false)
            this.props.disablePaymentLater(true)
            let splitPayment = this.state.splitPayments
            splitPayment.map((payment, index) => {
              if (payment.paymentMethod === "credit-card") {
                splitPayment[index].uniqueId = paymentHistoryData?._id
              }
              return null
            })
            this.setState({splitPayment:splitPayment},()=>{ 
              if(this.props.parentComponentState.isSplitPaymentOption){
                this.props.createOrderForTerminal(true, true);
              }
              })
            this.props.handleTerminalStatus('Success', data)
            await this.setAsyncState({
              paymentProgress: false,
              isChargeDevice: true,
              connectionStatus: value.data.connectionStatus,
              isChargeCard: false,
              data
            })
            this.props.setErrorMessage("Customer charged successfully")
          } else {
            this.props.handleTerminalStatus('Decline', data)
            this.props.updateInProgressTerminalPayment(false)
            this.props.updateFailedPaymentStatus(true)
            if(Code === "" && message === "cancelledAtTerminal"){
              this.props.setErrorMessage("Payment cancelled at terminal. Please try again or use another payment method.")
            }
            else if(Code === "" && message === "transactionAmount field is required"){
              this.props.setErrorMessage("Transaction amount should be greater than 0")
            }
            else if(Code !== "") {
              const Matchingmessage = responseCode(Code);
              if(Matchingmessage){
                this.props.setErrorMessage(Matchingmessage.message)
              }
            }
            else {
              this.props.setErrorMessage("Card could not be charged, please try again.")
            }
            await this.setAsyncState({
              paymentProgress: false,
              isChargeDevice: false,
              connectionStatus: value.data.connectionStatus,
              isChargeCard: false,
              data
            })
          }
        }
      } catch (err) {
        this.setState({
          enableTerminalButton: true,
          paymentProgress: false,
          isError: true,
          errorMessage: err.message,
          connectionStatus: "",
        });
      }
    }
  }

  handleSelectPaymentMethod = (paymentMethodType) => {
    const { selectedSplitPaymentMethods, splitPayments } = this.state;
    const updatedPaymentMethods = selectedSplitPaymentMethods.includes(paymentMethodType)
      ? selectedSplitPaymentMethods.filter((method) => method !== paymentMethodType)
      : [...selectedSplitPaymentMethods, paymentMethodType];
    const updatedSplitPayments = splitPayments.map((payment) => {
      if (payment.paymentMethod === paymentMethodType) {
        payment = { ...payment, paymentAmount: null, isSelectedPayment: false };
      }
      if (paymentMethodType === "check" && !updatedPaymentMethods.includes("check")) {
        this.setState({
          frontCheckImage: "",
          backCheckImage: "",
        });
      }
      if (updatedPaymentMethods.includes(payment.paymentMethod)) {
        if (paymentMethodType === "terminal") {
          this.setState({ isTerminalPayment: true }, () => {
            this.fetchTerminalDevices()
          })
        }
        payment = { ...payment, isSelectedPayment: true };
      } else {
        if (paymentMethodType === "credit-card" || paymentMethodType === "terminal") {
          if(paymentMethodType === "credit-card"){
            this.setState({totalCardAmountWithFee: {}})
          }
          if(paymentMethodType === "terminal"){
           this.setState({ totalTerminalAmountWithFee: {}})
          }
        }
        if (paymentMethodType === "check") {
          this.props.checkNumber("", "checkNumber");
        }
      }
      return payment;
    });
    if (paymentMethodType === "credit-memo") {
      this.setState({ selectedCreditMemos: [], remainingCreditAmount: 0 })
    }
    this.props.setErrorMessage("")
    this.props.splitPayments(updatedSplitPayments,this.state.remaingSplitAmount);
    this.setState({
      selectedSplitPaymentMethods: updatedPaymentMethods,
      splitPayments: updatedSplitPayments,
      showCreditMemoOptions: false,
    }, () => { this.props.UpdateSelectedSplitPaymentMethods(updatedPaymentMethods); this.props.isCalculteOrder(true) });
    
    return updatedPaymentMethods;
  };

  calcFee(amount, currency) {
    const _fee = this.props.user?.company?.chargeStripeCardFeesAmount ? this.props.user?.company?.chargeStripeCardFeesAmount : "3"
    let fee = 0
    amount = this.round2decimal(amount);
    fee =
      amount * (this.round2decimal(_fee) / 100) 
    fee = this.round2decimal(fee);
    const total = this.round2decimal(amount + fee);
    return {
      amount: this.round2decimal(amount),
      fee: this.round2decimal(fee),
      total: this.round2decimal(total),
    };
  }

  round2decimal = (number) => {
    return Number(numberFormat(Math.round(parseFloat(number) * 1000) / 1000));
  };

  async handleValidateOrder() {
    const result = await this.props.validateOrder(false, true); 
    if (result) {
      this.chargeOnDevice()
    }
  }

  handleSelectPaymentAmount = (paymentMethodType, amount, totalWithOutFee) => {
    let remaningAmount = 0
    let avaliableAmount = 0
    const { selectedCreditMemos } = this.state;
    const numericAmount = amount === ""  || amount < 0 ? null : parseFloat(amount);
    let splitMethodPayments = this.state.splitPayments.map(payment => ({ ...payment }));
    // let splitMethodPayments = _.clone(splitPayments)
    for (let i = 0; i < splitMethodPayments.length; i++) {
      let payment = splitMethodPayments[i];
      if (payment.paymentMethod === paymentMethodType) {
        splitMethodPayments[i].paymentAmount = numericAmount
      }
      avaliableAmount = Number((Number(avaliableAmount) + Number(splitMethodPayments[i].paymentAmount)).toFixed(2))
      if (this.state.selectedSplitPaymentMethods.includes("credit-card") && payment.paymentMethod === "credit-card" && paymentMethodType === "credit-card") {
        const totalcreditCardWithFee = this.calcFee(splitMethodPayments[i].paymentAmount, "USD")
        this.setState({ totalCardAmountWithFee: totalcreditCardWithFee })
      }
      if (this.state.selectedSplitPaymentMethods.includes("terminal") && paymentMethodType === "terminal" && payment.paymentMethod === "terminal") {
        const totalTerminalAmountWithFee = this.calcFee(splitMethodPayments[i].paymentAmount, "USD")
        this.setState({ totalTerminalAmountWithFee: totalTerminalAmountWithFee })
      }
      if (this.state.selectedSplitPaymentMethods.includes("credit-memo") && paymentMethodType === "credit-memo" && payment.paymentMethod === "credit-memo") {
        if (selectedCreditMemos.length === 0) {
          splitMethodPayments[i].paymentAmount = null
        } else {
          splitMethodPayments[i].creditMemoIds = this.getUsefulCreditMemos(amount);
        }
        // const remainingCreditAmount = this.calcFee(splitMethodPayments[i].paymentAmount, "USD")
        this.setState({ remainingCreditAmount: selectedCreditMemos.length > 0 ? splitMethodPayments[i].paymentAmount || 0 : 0 })
      }
    }
    let checkCreditAmountTotal = numericAmount === null ? 0 : numericAmount
    const checkOrderAmount = Number((Number(totalWithOutFee) - Number(this.props.paidAmount)).toFixed(2))
    if ((this.state.selectedSplitPaymentMethods.includes("credit-memo") && (paymentMethodType === "credit-memo")) ? (((Number((avaliableAmount).toFixed(2)) <= (Number(checkOrderAmount).toFixed(2))) && (this.getTotalSelectedCreditAmount() >= parseFloat(checkCreditAmountTotal)))) : (Number((avaliableAmount).toFixed(2)) <= (Number(checkOrderAmount).toFixed(2)))) {
      remaningAmount = Number((Number(checkOrderAmount) - Number(avaliableAmount)).toFixed(2))
      this.setState({
        splitPayments: splitMethodPayments,
        availableSplitAmount: avaliableAmount, remaingSplitAmount: remaningAmount,
        isError: false
      })
      this.props.splitPayments(splitMethodPayments, remaningAmount)
    } else {
      this.setState({ reRender: !this.state.reRender })
    }
    this.props.isCalculteOrder(true)
    this.props.setErrorMessage("")
  }

  // round2decimal = (number) => {
  //   return Math.round(parseFloat(number) * 1000) / 1000;
  // };

  getUsefulCreditMemos = (amount) => {
    const A = this.props.customerCredits;
    const B = this.state.selectedCreditMemos;
    let sum = 0;
    const result = [];

    for (const item of A) {
      if (B.includes(item._id)) {
        sum += item.receiveCreditAmount;
        if (this.round2decimal(sum) >= this.round2decimal(amount)) {
          result.push(item._id);
          break;
        } else {
          result.push(item._id);
        }
      }
    }
    return result;
  }
  getTotalCustomerCredits = () => {
    let total = 0;
    // Loop through each transaction object in the array
    for (let i = 0; i < this.props.customerCredits.length; i++) {
      // Add the receiveCreditAmount value to the total
      total += this.props.customerCredits[i]?.receiveCreditAmount;
    }
    return (total.toFixed(2));
  }

  getTotalSelectedCreditAmount = () => {
    const { selectedCreditMemos } = this.state;
    let total = 0;
    // Iterate through each object in the totalArray
    this.props.customerCredits.forEach(obj => {
      // Check if the _id of the object exists in the selectedArray
      if (selectedCreditMemos.includes(obj._id)) {
        // Add the receiveCreditAmount to the total
        total += obj.receiveCreditAmount;
      }
    });
    return total
  }

  getRemainingCredits = () => {
    const { selectedCreditMemos, remainingCreditAmount } = this.state;
    let total = 0;
    // Iterate through each object in the totalArray
    this.props.customerCredits.forEach(obj => {
      // Check if the _id of the object exists in the selectedArray
      if (selectedCreditMemos.includes(obj._id)) {
        // Add the receiveCreditAmount to the total
        total += this.round2decimal(obj.receiveCreditAmount);
      }
    });
    return (total - remainingCreditAmount).toFixed(2);
  }

  handleSelectCreditMemo = (id) => {
    const array = this.state.selectedCreditMemos
    const index = array.findIndex(item => item === id);
    if (!array.includes(id)) {
      // If object is not in the array, add it
      array.push(id);
    } else {
      // If object is in the array, remove it
      array.splice(index, 1);
    }
    const updatedSplitPayments = [...this.state.splitPayments];
    updatedSplitPayments[4].paymentAmount = null;
    updatedSplitPayments[4].creditMemoIds = [];
    this.props.assignSelectedCreditmemos(array)
    this.setState({ selectedCreditMemos: array, splitPayments: updatedSplitPayments })
  }

  handleOutsideClick = (event) => {
    const div = this.creditMemoRef.current;
    if (div && !div.contains(event.target)) {
      this.setState({ showCreditMemoOptions: false })
    }
  };

  handleCreditMemo = (showCreditMemoOptions) => {
    this.setState({
      showCreditMemoOptions: !showCreditMemoOptions
    })
  }

  handleSignCallback(data) {
    this.setState({ signatureUrl: data?.signatureUrl, saveSignature: data?.signatureUrl });
    this.props.signatureData(data?.signatureUrl);
  }

  fetchTerminalDeviceStatus = async () => {
    this.props.setErrorMessage("");
    const { selectedDevice } = this.state;
    const { value } = await this.props.fetchDeviceStatus(selectedDevice);
    if (value.status) {
      this.setState({
        connectionStatus: value.data.connectionStatus
      });
    }
  }


  render() {
    const { customerPaymentTerms, devices, selectedDevice, selectedSplitPaymentMethods,
      showCreditMemoOptions, selectedCreditMemos,isTerminalAvaliable } = this.state;
    const { totalWithFee, customerCredits, parentComponentState } = this.props;
    return (
      <div className="payment-terms-wrapper payment-option-wrapper">
        <div className="payment-terms-list-container">
          <div className="payment-terms-header-section">
            <h3 className="title">Payment Terms</h3>
            <h3 className="sub-title">{customerPaymentTerms === "cod"
              ? "Cash On Delivery (COD)"
              : "On Account"}</h3>
          </div>
          {/* <div className="split-payment-checkbox">
            <input
              type="checkbox"
              id="splitPayment"
              name="splitPayment"
              className="custom-checkbox--input"
              checked={this.props.parentComponentState.handleisSplitPaymentOption}
              onChange={this.props.handleisSplitPaymentOption}
            />
            <label htmlFor="splitPayment">Split Payment</label>
          </div> */}
          { customerPaymentTerms === "cod" && <>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items pl-0">
              <div className="payment-terms-list d-flex align-items-center">
                <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                  <input
                    type="checkbox"
                    disabled={this.props.paidAmount > 0}
                    className="custom-checkbox--input"
                    checked={this.props.parentComponentState.handleisSplitPaymentOption}
                    onChange={()=>{this.props.handleisSplitPaymentOption();this.setState({remaingSplitAmount:0})}}
                  />
                  <span className="checkmark"></span>
                </label>
                <div>
                  <h3 className="payment-title">Split Payment</h3>
                </div>
              </div>
            </div>
          </div>
          { this.props.parentComponentState.isSplitPaymentOption && (
            <ul className="payment-terms-list">
              {
                _.size(customerCredits) > 0 && <div className="row payment-terms-list-items align-items-start border-bottom pb-3">
                  <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items pl-0">
                    <div className="d-flex align-items-center w-100">
                      <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                        <input
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={selectedSplitPaymentMethods.includes("credit-memo")}
                          onChange={() => { this.handleSelectPaymentMethod("credit-memo") }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <div ref={this.creditMemoRef} className="custom__checkbox-select w-100 mb-0">
                        <div className={`form-group material-textfield ${!this.state.selectedSplitPaymentMethods.includes("credit-memo") ? "disabled" : ""}`} onClick={() => this.handleCreditMemo(showCreditMemoOptions)}>
                          <div className="form-control custom-select w-150 material-textfield-input pr-22">
                            Select
                          </div>
                          <label className="material-textfield-label">
                            Credit Memo<span>*</span>
                          </label>
                          <div className="btn btn-link add-new-card-btn mt-1">Available Credits: ${this.getTotalCustomerCredits()}</div>
                        </div>
                        {selectedSplitPaymentMethods.includes("credit-memo") && showCreditMemoOptions &&
                          <ul className="common__check-dropdown-list payment-terms-custom-checkbox-list close-dropdown-">
                            <div className="scrollable-list-container">
                              {customerCredits.map((cm) => <li key={cm._id} className="common__check-dropdown-list-items">
                                <span className="d-flex -align-items-center filter__check">
                                  <label className="custom-checkbox custom-checkbox-line">
                                    {`${cm.creditMemoNumber} ($${this.numberWithCommas(Number(Number(cm.receiveCreditAmount).toFixed(2)))})`}
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={selectedCreditMemos.includes(cm._id)}
                                      onChange={() => { this.handleSelectCreditMemo(cm._id) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </span>
                              </li>)
                              }
                            </div>
                          </ul>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                    <div className="form-group material-textfield mb-0 w-100">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={'$'}
                        thousandSeparator={true}
                        disabled={!selectedSplitPaymentMethods.includes("credit-memo")}
                        displayType={'input'}
                        type='text'
                        decimalScale={2}
                        value={this.state.splitPayments[4].paymentAmount === null ? "" : this.state.splitPayments[4].paymentAmount}
                        className='form-control material-textfield-input mb-0'
                        onValueChange={(_values) => {
                          const { value } = _values;
                          this.handleSelectPaymentAmount("credit-memo", value, parentComponentState.splitpaymentCalculationAmount)
                        }}
                        // name='dumpRates.pricePerTonLight'
                        required
                      />
                      <label className="material-textfield-label">
                        Enter Amount
                      </label>
                      {selectedSplitPaymentMethods.includes("credit-memo") && <div className="btn btn-link add-new-card-btn mt-1">Remaining Credit Amount: ${this.getRemainingCredits()}</div>}
                    </div>
                  </div>
                </div>
              }
              <div className="row payment-terms-list-items align-items-start border-bottom pb-3">
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items pl-0">
                  <div className="d-flex align-items-center w-100">
                    <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                      <input
                        type="checkbox"
                        className="custom-checkbox--input"
                        checked={this.state.selectedSplitPaymentMethods.includes("credit-card")}
                        onChange={() => { this.handleSelectPaymentMethod("credit-card") }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <div className="form-group material-textfield w-100 mb-0">
                      <select
                        // className="form-control custom-select w-150 material-textfield-input pr-22 mb-3"
                        className="form-control custom-select w-150 material-textfield-input pr-22"
                        required
                        disabled={!this.state.selectedSplitPaymentMethods.includes("credit-card")}
                        value={this.state.cardId}
                        onChange={(e) => {
                          if (this.props.paybliEnable) {
                            this.props.assignCard(
                              e.target.value,
                              "payabli"
                            );
                            this.setState({ cardId: e.target.value, payMethod: "payabli" })
                          } else {
                            this.props.assignCard(
                              e.target.value,
                              "payengine"
                            );
                            this.setState({ cardId: e.target.value, payMethod: "payengine" })
                          }
                        }}
                      >
                        <option>Select Credit Card</option>
                        {this.props.paybliEnable && this.state.customerPayabliData?.StoredMethods?.length >
                          0 && this.state.customerPayabliData?.StoredMethods.map((item) =>
                            <option value={item.IdPmethod}>
                              {item.MaskedAccount}{" "}{this.insertSlash(item.ExpDate)}{" "}
                            </option>
                          )}
                        {!this.props.paybliEnable &&
                          _.get(this.state.customerData, "paymentmethods", []).map((payment_method_details) => {
                            const { _id = "", details = {} } = payment_method_details;
                            return (
                              <option value={_id}>
                                {details.last4digits} &nbsp;{details?.expMonth}/{details?.expYear}{" "}</option>
                            )
                          })
                        }
                      </select>
                      <label className="material-textfield-label">
                        Select Credit Card
                      </label>
                      <button
                        onClick={() => {
                          this.setState({ isAddNewCard: true }, () => {
                            this.forceUpdate()
                          });
                        }}
                        className="btn btn-link add-new-card-btn">Add New Card</button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                  <div className="form-group material-textfield mb-0 w-100">
                    <NumberFormat
                      allowEmptyFormatting={true}
                      prefix={'$'}
                      thousandSeparator={true}
                      disabled={this.props.parentComponentState.inProgressCreditCardPayment || !this.state.selectedSplitPaymentMethods.includes("credit-card")}
                      displayType={'input'}
                      type='text'
                      decimalScale={2}
                      value={this.state.splitPayments[0].paymentAmount === null ? "" : this.state.splitPayments[0].paymentAmount}
                      className='form-control material-textfield-input mb-3'
                      onValueChange={(_values) => {
                        const { value } = _values;
                        this.handleSelectPaymentAmount("credit-card", value, parentComponentState.splitpaymentCalculationAmount)
                      }}
                      // name='dumpRates.pricePerTonLight'
                      required
                    />
                    <label className="material-textfield-label">
                      Enter Amount
                    </label>
                    {(this.state.selectedSplitPaymentMethods.includes("credit-card") && this.props.user?.company?.chargeStripeCardFees) &&
                      <div className="handing-fee-lable"><span>Handling Fee</span> : ${this.numberWithCommas(Number(isNaN(this.state.totalCardAmountWithFee?.fee) ? "0.00" : this.state.totalCardAmountWithFee?.fee))}</div>}
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                  <button
                    disabled={this.props.parentComponentState.inProgressCreditCardPayment || !this.state.selectedSplitPaymentMethods.includes("credit-card")}
                    onClick={() => {
                      this.props.chargeCreditCard(true)
                    }}
                    className="btn btn-yellow charge-card-btn"> {!this.props.parentComponentState.inProgressCreditCardPayment ? "Charge Card" : "Processing payment ..."}</button>
                </div>
              </div>
              <div className="row payment-terms-list-items border-bottom pb-3">
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items pl-0">
                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                    <input
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.selectedSplitPaymentMethods.includes("cash")}
                      onChange={() => { this.handleSelectPaymentMethod("cash") }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div>
                    <h3 className="payment-title">Cash</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                  <div className="form-group material-textfield w-100 mb-0">
                    <NumberFormat
                      allowEmptyFormatting={true}
                      prefix={'$'}
                      thousandSeparator={true}
                      disabled={!this.state.selectedSplitPaymentMethods.includes("cash")}
                      displayType={'input'}
                      type='text'
                      decimalScale={2}
                      value={this.state.splitPayments[1].paymentAmount === null ? "" : this.state.splitPayments[1].paymentAmount}
                      className='form-control material-textfield-input'
                      onValueChange={(_values) => {
                        const { value } = _values;
                        this.handleSelectPaymentAmount("cash", value, parentComponentState.splitpaymentCalculationAmount)
                      }}
                      // name='dumpRates.pricePerTonLight'
                      required
                    />
                    <label className="material-textfield-label">
                      Enter Amount
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items mobile-none">
                  &nbsp;
                </div>
              </div>
              <div className="row payment-terms-list-items">
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items pl-0">
                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                    <input
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.selectedSplitPaymentMethods.includes("check")}
                      onChange={() => { this.handleSelectPaymentMethod("check") }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div>
                    <h3 className="payment-title">Check</h3>
                  </div>
                  <div className="form-group material-textfield w-100 mb-0 ml-4">
                    <input
                      type="text"
                      className='form-control material-textfield-input'
                      disabled={!this.state.selectedSplitPaymentMethods.includes("check")}
                      value={this.props.parentComponentState?.checkDetails?.checkNumber}
                      onChange={(e) => {
                        this.props.checkNumber(e.target.value, "checkNumber");
                      }}
                      required
                    />
                    <label className="material-textfield-label">
                      Check Number <span>*</span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                  <div className="form-group material-textfield w-100 mb-0">
                    <NumberFormat
                      allowEmptyFormatting={true}
                      prefix={'$'}
                      thousandSeparator={true}
                      disabled={!this.state.selectedSplitPaymentMethods.includes("check")}
                      displayType={'input'}
                      type='text'
                      decimalScale={2}
                      value={this.state.splitPayments[2].paymentAmount === null ? "" : this.state.splitPayments[2].paymentAmount}
                      className='form-control material-textfield-input'
                      onValueChange={(_values) => {
                        const { value } = _values;
                        this.handleSelectPaymentAmount("check", value, parentComponentState.splitpaymentCalculationAmount)
                      }
                      }
                      // name='dumpRates.pricePerTonLight'
                      required
                    />
                    <label className="material-textfield-label">
                      Enter Amount
                    </label>
                  </div>
                </div>
              </div>
              {this.state.selectedSplitPaymentMethods.includes("check") &&
                <div className="row payment-terms-list-items front-back-upload-container">
                  <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items pl-0">
                    <label className="custom-checkbox custom-checkbox-line radio__checkbox" style={{ visibility: "hidden" }}>
                      <input
                        type="checkbox"
                        className="custom-checkbox--input"
                        checked={this.state.selectedSplitPaymentMethods.includes("check")}
                        onChange={() => { this.handleSelectPaymentMethod("check") }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <div style={{ visibility: "hidden" }}>
                      <h3 className="payment-title">Check</h3>
                    </div>
                    <div className="ml-4 payment-option-cal-max-width">
                      <label className="front-back-upload-label">Front of check</label>
                      {!this.state.isFrontCheckCameraOn && this.state.frontCheckImage === "" &&
                        <div className="button-group-container">
                          <input
                            type="file"
                            id="file"
                            ref="fileUploade"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              this.onChangeFile(e, "frontCheck")
                            }
                          />
                          <button onClick={() => { this.refs.fileUploade.click(); }}
                            className="btn btn-yellow charge-card-btn">Upload </button>
                          <div className="or-text">or</div>
                          <button onClick={() => this.setState({ isFrontCheckCameraOn: true, frontCheckImage: "", isFrontCameraUse: true })
                          } className="btn btn-yellow charge-card-btn">Take a photo </button>
                        </div>
                      }
                      {(this.state.isFrontCheckCameraOn ||
                        this.state.frontCheckImage) && (
                          <div className="card__dragdrop-space">
                            {this.state.frontCheckImage && (
                              <div className="react-html5-camera-img">
                                <img
                                  alt=""
                                  src={this.state.frontCheckImage}
                                ></img>
                                <button
                                  onClick={() => {
                                    this.setState({
                                      frontCheckImage: "",
                                      isFrontCheckCameraOn: false,
                                    });
                                    this.props.checkNumber(
                                      "",
                                      "frontCheckImage"
                                    );
                                  }}
                                  className="close"
                                >
                                  <img src={closeRed} alt="" />
                                </button>
                              </div>
                            )}
                            {this.state.isFrontCheckCameraOn && (
                              <Camera
                                onTakePhoto={(dataUri) =>
                                  this.onTakePhoto(
                                    dataUri,
                                    "frontCheck",
                                    "isCameraOn"
                                  )
                                }
                                // ref='fileUploader'
                                imageType={IMAGE_TYPES.PNG}
                                idealFacingMode={FACING_MODES.ENVIRONMENT}
                                isMaxResolution={true}
                                isImageMirror={false}
                                isSilentMode={false}
                                isDisplayStartCameraError={true}
                                isFullscreen={false}
                              />
                            )}
                          </div>
                        )}
                      {this.state.isFrontCheckCameraOn && (
                        <button
                          onClick={() =>
                            this.setState({ isFrontCheckCameraOn: false })
                          }
                          className="btn btn-yellow btn-sm edit-note w-100"
                        >
                          Close Camera{" "}
                        </button>
                      )}
                    </div>

                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                    <div className="payment-option-cal-max-width">
                      <label className="front-back-upload-label">Back of check</label>
                      {!this.state.isBackCheckCameraOn && this.state.backCheckImage === "" &&
                        <div className="button-group-container">
                          <input
                            type="file"
                            id="file"
                            ref="fileUploader"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              this.onChangeFile(e, "backCheck")
                            }
                          />
                          <button onClick={() => { this.refs.fileUploader.click(); }}
                            className="btn btn-yellow charge-card-btn">Upload </button>
                          <div className="or-text">or</div>
                          <button onClick={() => this.setState({ isBackCheckCameraOn: true })}
                            className="btn btn-yellow charge-card-btn">Take a photo </button>
                        </div>
                      }
                      {(this.state.isBackCheckCameraOn || this.state.backCheckImage) && (
                        <div className="card__dragdrop-space">
                          {this.state.backCheckImage && (
                            <div className="react-html5-camera-img">
                              <img
                                alt=""
                                src={this.state.backCheckImage}
                              ></img>
                              <button
                                onClick={() => {
                                  this.setState({
                                    backCheckImage: "",
                                    isBackCheckCameraOn: false,
                                  });
                                  this.props.checkNumber(
                                    "",
                                    "backCheckImage"
                                  );
                                }}
                                className="close"
                              >
                                <img src={closeRed} alt="" />
                              </button>
                            </div>
                          )}
                          {this.state.isBackCheckCameraOn && (
                            <Camera
                              onTakePhoto={(dataUri) =>
                                this.onTakePhoto(
                                  dataUri,
                                  "truckIsAvaliable",
                                  "isCameraOn"
                                )
                              }
                              ref="fileUploader"
                              imageType={IMAGE_TYPES.PNG}
                              idealFacingMode={FACING_MODES.ENVIRONMENT}
                              isMaxResolution={true}
                              isImageMirror={false}
                              isSilentMode={false}
                              isDisplayStartCameraError={true}
                              isFullscreen={false}
                            />
                          )}
                        </div>
                      )}
                      {this.state.isBackCheckCameraOn && (
                        <button
                          onClick={() =>
                            this.setState({ isBackCheckCameraOn: false })
                          }
                          className="btn btn-yellow btn-sm edit-note w-100"
                        >
                          Close Camera{" "}
                        </button>
                      )}
                    </div>
                  </div>
                </div>}
              <div className="row payment-terms-list-items border-top pt-3">
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items pl-0">
                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                    <input
                      type="checkbox"
                      className="custom-checkbox--input"
                      disabled={!isTerminalAvaliable}
                      checked={ this.state.selectedSplitPaymentMethods.includes("terminal")}
                      onChange={() => { this.handleSelectPaymentMethod("terminal") }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div>
                    <h3 className="payment-title mb-1">Terminal</h3>
                    <p className="terminal-status">Terminal Status: <span className="connected">{this.state.connectionStatus}</span></p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                  <div className="form-group material-textfield w-100 mb-0">
                    <NumberFormat
                      allowEmptyFormatting={true}
                      prefix={'$'}
                      thousandSeparator={true}
                      disabled={ !isTerminalAvaliable || (parentComponentState.inProgressTerminalPayment || !this.state.selectedSplitPaymentMethods.includes("terminal"))}
                      displayType={'input'}
                      type='text'
                      decimalScale={2}
                      value={this.state.splitPayments[3].paymentAmount === null ? "" : this.state.splitPayments[3].paymentAmount}
                      className='form-control material-textfield-input mb-3'
                      onValueChange={(_values) => {
                        const { value } = _values;
                        this.handleSelectPaymentAmount("terminal",value, parentComponentState.splitpaymentCalculationAmount)
                      }}
                      required
                    />
                    <label className="material-textfield-label">Enter Amount </label>
                    {(this.state.selectedSplitPaymentMethods.includes("terminal") && this.props.user?.company?.chargeStripeCardFees) &&
                      <div className="handing-fee-lable"><span>Handling Fee</span> (2.9% + 30¢): ${this.numberWithCommas(Number(isNaN(this.state.totalTerminalAmountWithFee?.fee) ? "0.00" : this.state.totalTerminalAmountWithFee?.fee))}</div>}
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                  <button className="btn btn-yellow charge-card-btn"
                    onClick={() => { this.chargeOnDevice() }}
                    disabled={ !isTerminalAvaliable || (parentComponentState.inProgressTerminalPayment || !this.state.selectedSplitPaymentMethods.includes("terminal"))}
                  >Charge On Terminal</button>
                </div>
              </div>
              <div className="row">
                {this.state.isTerminalPayment && devices &&
                  devices.length > 0 &&
                  devices.map((device) => {
                    return (
                      <div
                        key={device.deviceId}
                        className="col-lg-4 col-md-12 col-sm-12 col-xs-12"
                      >
                        <div
                          className={
                            device.deviceId === selectedDevice
                              ? "device-card device-card-selected"
                              : "device-card"
                          }
                          onClick={() =>
                            this.setState({
                              selectedDevice: device.deviceId,
                            })
                          }
                        >
                          <div className="device-nickname">
                            Device Nickname: { this.props.paybliEnable ? device.deviceNickName : device.deviceNickname}
                          </div>
                          <div className="device-details">
                            <div className="device-serial-no">
                              <span className="serial-label">
                                Serial No:
                              </span>
                              <span className="serial-no">
                                {device.serialNumber}
                              </span>
                            </div>
                            <div className="device-model">
                              Model: {device.model}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
              <div className="payment-terms-list-inner-items total-amount-title payment-description-list">
                <h3 className="amount-title"> Order Amount: ${this.numberWithCommas(Number(totalWithFee.amount))}</h3>
                <h3 className="amount-title"> Total Order Amount : ${this.numberWithCommas(Number(totalWithFee.total))}</h3>
                <h3 className="amount-title"> Paid Amount : ${this.numberWithCommas(Number(this.props.paidAmount))}</h3>
                <h3 className="amount-title"> Handling Fee : ${this.numberWithCommas(Number(parentComponentState.totalHandlingFeeAmount))}</h3>
                <h3 className="amount-title"> Amount Left to Charge: ${this.state.remaingSplitAmount === null ? this.props.paidAmount === null ? this.numberWithCommas(Number(totalWithFee.total)) : this.numberWithCommas(Number(totalWithFee.total - this.props.paidAmount)) : this.numberWithCommas(Number(this.state.remaingSplitAmount))}</h3>
              </div>
                {this.props.errorMessage !== "" ? 
                <div className="payment-terms-list-inner-items total-amount-title mt-3 credit-amout-error">
                <p className="amount-title text-red m-10" style={{ fontWeight: "700", fontSize: "18px", margin: "auto" }}>{this.props.errorMessage}</p> 
                </div>
                : ""}
              {this.props.parentComponentState.isCreditCardPaymentDone &&
                <div className="payment-terms-list-inner-items total-amount-title mt-3 credit-amout-success">
                  <p className="amount-title text-green m-10" style={{ fontWeight: "700", fontSize: "18px",margin: "auto" }}>Successfully charged</p>
                </div>
              }
              {this.state.isChargeDevice &&
                <div className="payment-terms-list-inner-items total-amount-title mt-3 credit-amout-success">
                  <p className="amount-title text-green m-10" style={{ fontWeight: "700", fontSize: "18px",margin: "auto" }}>Successfully charged</p>
                </div>
              }
              {(this.props.parentComponentState.inProgressCreditCardPayment || parentComponentState.inProgressTerminalPayment) &&
                <div className="payment-terms-list-inner-items total-amount-title mt-3 credit-amout-success">
                  <p className="amount-title text-green m-10" style={{ fontWeight: "700", fontSize: "18px",margin: "auto" }}>Please wait while the transaction is being processed.</p>
                </div>
              }
            </ul>
          )}
           </>}
             {customerPaymentTerms === "cod" && !this.props.parentComponentState.isSplitPaymentOption && (
            <ul className="payment-terms-list">
              {
                 <div>
                 <section>
                   <div className="row">
                     <div className="col-lg-12">
                       <div className="custome__tabs">
                         <ul className="custome__tabs-list">
                           <li>
                             <span
                               onClick={() => {
                                 this.props.paymentMethod("cod");
                                 this.setState({ activeTab: "cash" });
                                 this.props.activeTab("cash", false);
                               }}
                               className={
                                 this.state.activeTab === "cash" ? "active" : ""
                               }
                             >
                               Cash
                             </span>
                           </li>
                           {/* {this.props.paybliEnable ? ( */}
                           <li>
                             <span
                               onClick={() => {
                                 this.props.paymentMethod("creditcard");
                                 this.setState({ activeTab: "creditCard" });
                                 this.props.activeTab("creditCard", true);
                                 this.setState({
                                  isCard: true,
                                  isTerminalPayment: false,
                                  validationErrorMessage: ""
                                  
                                })
                               }}
                               className={
                                 this.state.activeTab === "creditCard"
                                   ? "active"
                                   : ""
                               }
                             >
                               Credit Card{" "}
                             </span>
                           </li>
                           {/* ) : null} */}
                           <li>
                             <span
                               onClick={() => {
                                 this.props.paymentMethod("check");
                                 this.setState({ activeTab: "check" });
                                 this.props.activeTab("check", false);
                               }}
                               className={
                                 this.state.activeTab === "check" ? "active" : ""
                               }
                             >
                               Check
                             </span>
                           </li>
                         </ul>
                       </div>
                     </div>
                   </div>
                 </section>
                 {this.state.activeTab === "cash" && (
                   <section className="payment_tabs-section">
                     <div className="cash__payment">
                       <div className="cash__payment-inner">
                         <div className="total__amount align-items-start">
                           <h2>Total Amount Due</h2>
                          <div>
                          <h2>
                             $
                             {this.props.totalAmountDue === 0
                               ? "-" 
                               : this.state.activeTab === "cash"
                               ? this.numberWithCommas(isRoundOff(this.props.totalAmountDue)(this.props.isRoundOffAmount))
                               : this.numberWithCommas(this.props.totalAmountDue)
                               }
                           </h2>
                           <div className="rounde-text text-center">
                           {this.props?.isRoundOffAmount && <small>(Rounded)</small>}
                           </div>
                          </div>
                         </div>
                         <div className="cash__collected">
                           <h2 className="title">Cash Collected</h2>
                           <div className="form-group material-textfield unit__price-section">
                           <NumberFormat
                               thousandSeparator={true}
                               displayType={"input"}
                               prefix={"$"}
                               decimalScale={0}
                               fixedDecimalScale={true}
                               value={this.state.customerCashAmout}
                               allowEmptyFormatting={false}
                               className="form-control material-textfield-input"
                               onValueChange={(e) => {
                                 this.setState({
                                   customerCashAmout: e.floatValue,
                                   customerCashValue: e.floatValue,
                                 });
                               }}
                               required
                             />
                             <label className="material-textfield-label">
                               Amount of cash customer gave you
                             </label>
                           </div>
                         </div>
                         <div className="change__mony">
                           <h3>Change Due (to give back to customer)</h3>
                           <h2>
                             $
                             {this.state.customerCashValue === 0
                               ? "-"
                               : this.numberWithCommasAndRound(
                                   this.state.customerCashValue -
                                   isRoundOff(this.props.totalAmountDue)(this.props.isRoundOffAmount)
                                 ) === "NaN"
                               ? "-"
                               : this.numberWithCommasAndRound(
                                   this.state.customerCashValue -
                                   isRoundOff(this.props.totalAmountDue)(this.props.isRoundOffAmount)
                                 )}
                           </h2>
                         </div>
                       </div>
                     </div>
                   </section>
                 )}{" "}
                 {this.state.activeTab === "creditCard" && (
                   <section className="credit__cardtabs-section">
                     <div className="card__tabs-inner">
                       <div className="inbound__outbound-group">
                         <button
                           onClick={() => {
                             this.setState({
                               isCard: true,
                               isTerminalPayment: false,
                               selectedDevice:"",
                               validationErrorMessage: ""
                             });
                             this.props.isTerminalTabActive(false)
                             this.props.activeTab("creditCard", false);
                             if(this.state.cardId !== ""){
                               this.props.assignCard(
                                 this.state.cardId,
                                 this.state.payMethod
                               );
                             }
                           }}
                           className={`${
                             this.state.isCard
                               ? "btn btn-inbound active"
                               : "btn btn-outbound"
                           }`}
                         >
                           Card
                         </button>
                         <button
                           onClick={() => {
                             this.setState({
                               isCard: false,
                               isTerminalPayment: true,
                               validationErrorMessage: ""
                             },()=>{
                               this.props.isTerminalTabActive(true)
                               this.fetchTerminalDevices();
                               this.props.activeTab("terminal", false)
                               this.props.assignCard(
                               "",
                               ""
                               );
                             });
                           }}
                           className={`${
                             this.state.isTerminalPayment
                               ? "btn btn-inbound active"
                               : "btn btn-outbound"
                           }`}
                         >
                           Terminal Payment
                         </button>
                       </div>
                       {this.state.isCard && (
                         <>
                           <h2 className="card__title">Card(s) on file </h2>
                           <ul className="filter__list">
                             {this.props.paybliEnable && this.state.customerPayabliData?.StoredMethods?.length >
                               0 &&
                               this.state.customerPayabliData?.StoredMethods.map(
                                 (item) => {
                                  const cardBrand = item?.Descriptor?.toLowerCase() ?? "visa";
                                  const cardImage = this.state.cardImageData[cardBrand] || visa2; 
                                   return (
                                     <li className="filter__list-item">
                                       <span className="d-flex -align-items-center filter__check">
                                         <label className="custom-checkbox custom-checkbox-line">
                                           <input
                                             type="checkbox"
                                             checked={this.state.cardId === item.IdPmethod}
                                             className="custom-checkbox--input"
                                             onChange={() => {
                                               this.props.assignCard(
                                                 item.IdPmethod,
                                                 "payabli"
                                               );
                                               this.setState({cardId:item.IdPmethod,payMethod:"payabli"})
                                             }}
                                           />
                                           <span className="checkmark"></span>
                                           <p className="card__number">
                                             <img src={cardImage} alt={cardBrand}/>
                                             {item.MaskedAccount}{" "}
                                             <span>
                                               {this.insertSlash(item.ExpDate)}{" "}
                                             </span>
                                           </p>
                                         </label>
                                       </span>
                                     </li>
                                   );
                                 }
                               )}
                             {!this.props.paybliEnable && _.get(
                               this.state.customerData,
                               "paymentmethods",
                               []
                             ).map((payment_method_details) => {
                               const { _id = "", details = {} } =
                                 payment_method_details;
                                 const cardBrand = details?.Descriptor?.toLowerCase() ?? "visa"; 
                                 const cardImage = this.state.cardImageData[cardBrand] || visa2; 
                               return (
                                 <li key={_id} className="filter__list-item">
                                   <span className="d-flex -align-items-center filter__check">
                                     <label className="custom-checkbox custom-checkbox-line">
                                       <input
                                         type="checkbox"
                                         checked={this.state.cardId === payment_method_details._id}
                                         className="custom-checkbox--input"
                                         onChange={() => {
                                           this.props.assignCard(
                                             payment_method_details._id,
                                             "payengine"
                                           );
                                           this.setState({cardId:payment_method_details._id,payMethod:"payengine"})
                                         }}
                                       />
                                       <span className="checkmark"></span>
                                       <p className="card__number">
                                         <img src={cardImage} alt={cardBrand} />
                                         {details.last4digits} &nbsp;
                                         <span>
                                           {details?.expMonth}/{details?.expYear}{" "}
                                         </span>
                                       </p>
                                     </label>
                                   </span>
                                 </li>
                               );
                             })}
                           </ul>
                           <h2
                             onClick={() => {
                               this.setState({ isAddNewCard: true },()=>{
                                 this.forceUpdate()
                               });
                             }}
                             className="add__newcredit-card"
                           >
                             + Add a new credit card{" "}
                           </h2>
                         </>
                       )}
                       {this.state.customerPayabliData?.StoredMethods?.length === 0 &&
                         _.get(this.state.customerData, "paymentmethods", []).length === 0 && (
                             <h2 className="blank__card-text">No Cards on file </h2>
                           )}
     
                       { this.state.isTerminalPayment && devices &&
                         devices.length > 0 &&
                         devices.map((device) => {
                           return (
                             <div
                               key={device.deviceId}
                               className="col-md-12"
                             >
                               <div
                                 className={
                                   device.deviceId === selectedDevice
                                     ? "device-card device-card-selected"
                                     : "device-card"
                                 }
                                 onClick={() => {
                                  this.setState({
                                    selectedDevice: device.deviceId,
                                  },()=>{
                                    if(!this.props.paybliEnable ){
                                    this.fetchTerminalDeviceStatus();
                                    }
                                  });
                                }}
                               >
                               <div className="device-nickname">
                               Device Nickname: { this.props.paybliEnable ? device.deviceNickName : device.deviceNickname}
                               </div>
                               <div className="device-details">
                                 <div className="device-serial-no">
                                   <span className="serial-label">
                                     Serial No:
                                   </span>
                                   <span className="serial-no">
                                     {device.serialNumber}
                                   </span>
                                 </div>
                                 <div className="device-model">
                                   Model: {device.model}
                                 </div>
                               </div>
                             </div>
                           </div>
                           );
                         })
                       }
                       {this.state.isTerminalPayment && (
                         <>
                           <button
                           disabled={this.state.paymentProgress}
                             onClick={() => {
                              this.handleValidateOrder();
                             }}
                             className="btn btn-yellow btn-sm d-block m-auto"
                           >
                             Charge Card on Terminal{" "}
                           </button>
                         </>
                       )}
                        {this.state.selectedDevice !== '' ? (
                         <p className="terminal__status">
                           Terminal Status:{" "}
                           <span className="green">
                             {this.state.connectionStatus}
                           </span>
                         </p>
                       ) :null}
                       {this.state.paymentStatus === "Declined" && (
                         <p className="terminal__status">
                           Payment Status:{" "}
                           <span className="red">
                            Payment has been Declined
                           </span>
                         </p>
                       )}
                       {this.state.validationError && (
                         <p className="terminal__status">
                           <span className="red">
                            {this.state.validationErrorMessage}
                           </span>
                         </p>
                       )}
                       {this.state.devices.length === 0  && this.state.isTerminalPayment ? (
                         <p className="terminal__status">
                         Terminal Status:{" "}
                         <span className="red">
                         No Device Connected
                         </span>
                       </p>
                       ) :null}
                       
                       {this.state.paymentStatus === "Success" ? (
                         <p className="terminal__status">
                           Payment Status:{" "}
                           <span className="green">
                             ${ this.numberWithCommas(Number(this.props.totalAmountDue +this.props.totalWithFee.fee))} successfully charged
                           </span>
                         </p>
                       ) : null}
                     </div>
                   </section>
                 )}
                 {this.state.activeTab === "check" && (
                   <section className="card__cheking-section">
                     <div className="card__cheking-section-inner">
                       <div className="form-group material-textfield unit__price-section">
                         <input
                           type="text"
                           className="form-control material-textfield-input"
                           onChange={(e) => {
                             this.props.checkNumber(e.target.value, "checkNumber");
                           }}
                           required
                         />
                         <label className="material-textfield-label">
                           Check Number<span>*</span>
                         </label>
                       </div>
                       <div className="card__checking-uploading">
                         <div className="list">
                           <div className="items">
                             <div className="card__dragdrop">
                               <h3 className="title">Front of check</h3>
                               {!this.state.isFrontCheckCameraOn &&
                                 this.state.frontCheckImage === "" && (
                                   <div className=" uplaod__button-section">
                                     <input
                                       type="file"
                                       id="file"
                                       ref="fileUploade"
                                       style={{ display: "none" }}
                                       onChange={(e) =>
                                         this.onChangeFile(e, "frontCheck")
                                       }
                                     />
                                     <button
                                       onClick={() => {
                                         this.refs.fileUploade.click();
                                       }}
                                       className="btn btn-yellow btn-sm edit-note"
                                     >
                                       Upload{" "}
                                     </button>
                                     <div className="or__text">Or</div>
                                     <button
                                       onClick={() =>
                                         this.setState({
                                           isFrontCheckCameraOn: true,
                                           frontCheckImage: "",
                                           isFrontCameraUse: true,
                                         })
                                       }
                                       className="btn btn-yellow btn-sm edit-note"
                                     >
                                       Take a photo{" "}
                                     </button>
                                   </div>
                                 )}
                               {(this.state.isFrontCheckCameraOn ||
                                 this.state.frontCheckImage) && (
                                 <div className="card__dragdrop-space">
                                   {this.state.frontCheckImage && (
                                     <>
                                       <img
                                         alt=""
                                         src={this.state.frontCheckImage}
                                       ></img>
                                       <button
                                         onClick={() => {
                                           this.setState({
                                             frontCheckImage: "",
                                             isFrontCheckCameraOn: false,
                                           });
                                           this.props.checkNumber(
                                             "",
                                             "frontCheckImage"
                                           );
                                         }}
                                         className="close"
                                       >
                                         <img src={closeRed} alt="" />
                                       </button>
                                     </>
                                   )}
                                   {this.state.isFrontCheckCameraOn && (
                                     <Camera
                                       onTakePhoto={(dataUri) =>
                                         this.onTakePhoto(
                                           dataUri,
                                           "frontCheck",
                                           "isCameraOn"
                                         )
                                       }
                                       // ref='fileUploader'
                                       imageType={IMAGE_TYPES.PNG}
                                       idealFacingMode={FACING_MODES.ENVIRONMENT}
                                       isMaxResolution={true}
                                       isImageMirror={false}
                                       isSilentMode={false}
                                       isDisplayStartCameraError={true}
                                       isFullscreen={false}
                                     />
                                   )}
                                 </div>
                               )}
                             </div>
                             {this.state.isFrontCheckCameraOn && (
                               <button
                                 onClick={() =>
                                   this.setState({ isFrontCheckCameraOn: false })
                                 }
                                 className="btn btn-yellow btn-sm edit-note w-100"
                               >
                                 Close Camera{" "}
                               </button>
                             )}
                           </div>
                           <div className="items">
                             <div className="card__dragdrop">
                               <h3 className="title">Back of check </h3>
                               {!this.state.isBackCheckCameraOn &&
                                 this.state.backCheckImage === "" && (
                                   <div className=" uplaod__button-section">
                                     <input
                                       type="file"
                                       id="file"
                                       ref="fileUploader"
                                       style={{ display: "none" }}
                                       onChange={(e) =>
                                         this.onChangeFile(e, "backCheck")
                                       }
                                     />
                                     <button
                                       onClick={() => {
                                         this.refs.fileUploader.click();
                                       }}
                                       className="btn btn-yellow btn-sm edit-note"
                                     >
                                       Upload{" "}
                                     </button>
                                     <div className="or__text">Or</div>
                                     <button
                                       onClick={() =>
                                         this.setState({ isBackCheckCameraOn: true })
                                       }
                                       className="btn btn-yellow btn-sm edit-note"
                                     >
                                       Take a photo{" "}
                                     </button>
                                   </div>
                                 )}
                               {(this.state.isBackCheckCameraOn ||
                                 this.state.backCheckImage) && (
                                 <div className="card__dragdrop-space">
                                   {this.state.backCheckImage && (
                                     <>
                                       <img
                                         alt=""
                                         src={this.state.backCheckImage}
                                       ></img>
                                       <button
                                         onClick={() => {
                                           this.setState({
                                             backCheckImage: "",
                                             isBackCheckCameraOn: false,
                                           });
                                           this.props.checkNumber(
                                             "",
                                             "backCheckImage"
                                           );
                                         }}
                                         className="close"
                                       >
                                         <img src={closeRed} alt="" />
                                       </button>
                                     </>
                                   )}
                                   {this.state.isBackCheckCameraOn && (
                                     <Camera
                                       onTakePhoto={(dataUri) =>
                                         this.onTakePhoto(
                                           dataUri,
                                           "truckIsAvaliable",
                                           "isCameraOn"
                                         )
                                       }
                                       ref="fileUploader"
                                       imageType={IMAGE_TYPES.PNG}
                                       idealFacingMode={FACING_MODES.ENVIRONMENT}
                                       isMaxResolution={true}
                                       isImageMirror={false}
                                       isSilentMode={false}
                                       isDisplayStartCameraError={true}
                                       isFullscreen={false}
                                     />
                                   )}
                                 </div>
                               )}
                             </div>
                             {this.state.isBackCheckCameraOn && (
                               <button
                                 onClick={() =>
                                   this.setState({ isBackCheckCameraOn: false })
                                 }
                                 className="btn btn-yellow btn-sm edit-note w-100"
                               >
                                 Close Camera{" "}
                               </button>
                             )}
                           </div>
                         </div>
                       </div>
                     </div>
                   </section>
                 )}
               </div>
              }
            </ul>
          )}
          {
            this.props?.user?.company?.isSignaturePadIntegration &&
            <SignaturePad orderId={this.props.order_id} parentCallback={(data) => this.handleSignCallback(data)} />
          }
            {this.props.driver && (
              <>
             <div className="driver__signature-section">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="signature__text">
                      Driver Signature
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="driver__signature-inner">
                      <div className="signature">
                        {/* <img src={signatureImg} alt='' /> */}
                        <SignatureCanvas
                          penColor="green"
                          // onEnd={() => setRetry(true)}
                          canvasProps={{
                          /*width: 500, minheight: 200,*/ className: "sigCanvas",
                          }}
                          ref={(ref) => {
                            this.signatureCanvasRef = ref;
                            this.setState({ SignatureCanvas: ref });
                          }}
                        />
                        {/* <button className='close'>
                        <img className='close-icon' src={closeGray} alt='' />
                      </button> */}
                      </div>
                      <div className="signature__right-section">
                        <button
                          onClick={() => {
                            this.state.SignatureCanvas.clear();
                            this.props.signatureData("");
                          }}
                          className="btn clear-btn"
                        >
                          Clear
                        </button>
                        <button
                          onClick={() => this.SaveSignature()}
                          className="btn save-btn"
                        >
                          Save Signature
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </>
            )}
          {this.state.isAddNewCard && this.props.paybliEnable ? (
            <AddCardComponent
              paybliDetails={this.props.paybliDetails}
              customerData={this.props.customerData}
              assignCardData={this.assignCardData}
              payabliRef={this.props?.payabliRef}
            />
          ) : this.state.isAddNewCard && (
            <>
              <PayEngineCardModal
                addCard={this.props.addCard}
                isCardModalOpen={this.state.isAddNewCard}
                closeModal={() => this.setState({ isAddNewCard: false })}
                fetchOrder={""}
                fetchCustomerDetail={this.props.fetchCustomerDetail}
                customerId={this.props.customerData._id}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}
