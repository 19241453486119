import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Formik, ErrorMessage } from 'formik';
import { PricingTierCreateEdit } from '../../utils/validations';
import  DumpRateSalesTaxSwitch  from './partials/pricingtier_toggle';
import ErrorFocus from '../../utils/error-focus';
// import DumpRates from "../DumpRates/container";
import NumberFormat from 'react-number-format';
import ReactModal from 'react-modal';
import { CloseIcon } from "../../components/icons";
import './styles.scss';
import { customStartCase } from '../../constants/common';


export default class CreateEditCompanyInfoCustomer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        dumpRates: {
          defaultOption: '',
          pricePerTonLight: '',
          pricePerTonHeavy: '',
         'pricePerTonMixedC&D': '',
          pricePerTonWood: '',
          pricePerTonMetal: '',
          pricePerTonRoofing: '',
          pricePerTonMasonry: '',
          pricePerTonBrick: '',
          pricePerTonDirt: '',
          pricePerTonConcrete: '',
          'pricePerTonC&D': '',
          pricePerTonLowRecovery: '',
          pricePerTonOpenContainer: '',
          pricePerTonClosedContainer: '',
          pricePerTonTrailer: '',
          pricePerTonAsbestos: '',
          pricePerYardLight: '',
          pricePerYardHeavy: '',
          'pricePerYardMixedC&D': '',
          pricePerYardWood: '',
          pricePerYardMetal: '',
          pricePerYardRoofing: '',
          pricePerYardMasonry: '',
          pricePerYardBrick: '',
          pricePerYardDirt: '',
          pricePerYardConcrete: '',
          'pricePerYardC&D': '',
          pricePerYardLowRecovery: '',
          pricePerYardOpenContainer: '',
          pricePerYardClosedContainer: '',
          pricePerYardTrailer: '',
          pricePerYardAsbestos: '',
        },
        saleTaxToggles: {
          pricePerTonLight: false,
          pricePerTonHeavy: false,
          'pricePerTonMixedC&D': false,
          pricePerTonWood: false,
          pricePerTonMetal: false,
          pricePerTonRoofing: false,
          pricePerTonMasonry: false,
          pricePerTonBrick: false,
          pricePerTonDirt: false,
          pricePerTonConcrete: false,
          'pricePerTonC&D': false,
          pricePerTonLowRecovery: false,
          pricePerTonOpenContainer: false,
          pricePerTonClosedContainer: false,
          pricePerTonTrailer: false,
          pricePerTonAsbestos: false,
          pricePerYardLight: false,
          pricePerYardHeavy: false,
          'pricePerYardMixedC&D': false,
          pricePerYardWood: false,
          pricePerYardMetal: false,
          pricePerYardRoofing: false,
          pricePerYardMasonry: false,
          pricePerYardBrick: false,
          pricePerYardDirt: false,
          pricePerYardConcrete: false,
          'pricePerYardC&D': false,
          pricePerYardLowRecovery: false,
          pricePerYardOpenContainer: false,
          pricePerYardClosedContainer: false,
          pricePerYardTrailer: false,
          pricePerYardAsbestos: false,
        },
        isSalesTax:false,
        additionalitemsToggle:{},
        pricingTier: '',
        additionalItems: [],
        capoutPrice: '',
        minimumPrice: '',
        mattress: '',
        tire: '',
        tv: '',
        sofa: '',
        refrigerator: '',
        appliances: '',
        hardToHandle: '',
        overage: '',
        waitTime: '',
        travelFee: '',
      },
      pleaseWait: false,
      isDisable: false,
      id: '',
      onSelectFocus: false,
      user: {},
      isEdit: false,
      addFixedItemsErrorMsg: '',
      isExistPriceTierName: '',
      isModelOpen: false,
      fieldName: '',
      fixedItemslist: [],
      companyId:'',
      modalTitle: 'Custom Fixed Item',
      defaultDumpRates: [
        'defaultOption',
        'pricePerTonLight',
        'pricePerTonHeavy',
        'pricePerTonMixedC&D',
        'pricePerTonWood',
        'pricePerTonMetal',
        'pricePerTonRoofing',
        'pricePerTonMasonry',
        'pricePerTonBrick',
        'pricePerTonDirt',
        'pricePerTonConcrete',
        'pricePerTonC&D',
        'pricePerTonLowRecovery',
        'pricePerTonOpenContainer',
        'pricePerTonClosedContainer',
        'pricePerTonTrailer',
        'pricePerTonAsbestos',
        'pricePerYardLight',
        'pricePerYardHeavy',
        'pricePerYardMixedC&D',
        'pricePerYardWood',
        'pricePerYardMetal',
        'pricePerYardRoofing',
        'pricePerYardMasonry',
        'pricePerYardBrick',
        'pricePerYardDirt',
        'pricePerYardConcrete',
        'pricePerYardC&D',
        'pricePerYardLowRecovery',
        'pricePerYardOpenContainer',
        'pricePerYardClosedContainer',
        'pricePerYardTrailer',
        'pricePerYardAsbestos'
      ],
      customerDumpRates: [
        'defaultOption',
        'pricePerTonLight',
        'pricePerTonHeavy',
        'pricePerTonMixedC&D',
        'pricePerTonWood',
        'pricePerTonMetal',
        'pricePerTonRoofing',
        'pricePerTonMasonry',
        'pricePerTonBrick',
        'pricePerTonDirt',
        'pricePerTonConcrete',
        'pricePerTonC&D',
        'pricePerTonLowRecovery',
        'pricePerTonOpenContainer',
        'pricePerTonClosedContainer',
        'pricePerTonTrailer',
        'pricePerTonAsbestos',
        'pricePerYardLight',
        'pricePerYardHeavy',
        'pricePerYardMixedC&D',
        'pricePerYardWood',
        'pricePerYardMetal',
        'pricePerYardRoofing',
        'pricePerYardMasonry',
        'pricePerYardBrick',
        'pricePerYardDirt',
        'pricePerYardConcrete',
        'pricePerYardC&D',
        'pricePerYardLowRecovery',
        'pricePerYardOpenContainer',
        'pricePerYardClosedContainer',
        'pricePerYardTrailer',
        'pricePerYardAsbestos'
      ]
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getPricingData();
    this.getFixedItems();
    this.fetchMe();
  }

  getPricingData = async () => {
    const urlArray = window.location.pathname.split('/')
    if (urlArray[2]) {
      this.setState({ isEdit: true })
      const id = urlArray[2]
      const {
        value: { data, status },
      } = await this.props.getPricingTierById(id);
      if (status) {
        const filteredArray = Object.keys(data.pricing.dumpRates).filter(item => item !== "minimumPrice" && item !== "capoutPrice");
        this.setState({customerDumpRates: filteredArray,
          initialValues: {
            ...this.state.initialValues,
            saleTaxToggles: data.salesTaxPricingTier.dumpRates,
            additionalItems:data.salesTaxPricingTier.additionalItems,
          }
        });
        
        this.initialData(data.pricing)
      }
    }
  }

  sortPricingTier = (data) => {
    // Convert the object into an array of [key, value] pairs
    const filteredDataPerTon = Object.entries(data).filter(([key, value]) => key.startsWith("pricePerTon") && value !== null);
    const sortedDataPerTon = Object.fromEntries(filteredDataPerTon)
    const filteredDataPerYard = Object.entries(data).filter(([key, value]) => key.startsWith("pricePerYard") && value !== null);
    const sortedDataPerYard = Object.fromEntries(filteredDataPerYard)
    const filteredData = Object.entries(data).filter(([key, value]) => !key.includes('pricePer') && value !== null);
    const sortedDataWithoutPer = Object.fromEntries(filteredData)
    const sortedDataTonYard = Object.assign({ ...sortedDataPerTon, ...sortedDataPerYard })
    const sortedData = Object.assign({ ...sortedDataTonYard, ...sortedDataWithoutPer })
    return sortedData;
  }

  getDumpRateOptions = () => {
    const filteredPrices = {};
    const initialValues = {...this.state.initialValues}
    const defaultOption = initialValues?.dumpRates?.defaultOption
    const defaultOptionValue = initialValues?.dumpRates[defaultOption]
    if(defaultOptionValue === null || defaultOptionValue === "" || isNaN(defaultOptionValue)){
      initialValues.dumpRates.defaultOption = ""
    }
    const sorted = this.sortPricingTier(this.state.initialValues.dumpRates)
    for (const key in sorted) {
      if (this.state.customerDumpRates.includes(key) && typeof sorted[key] === 'number' && sorted[key] >= 0) {
        filteredPrices[key] = sorted[key];
      }
    }
    const c = Object.keys(filteredPrices).filter((key) => this.state.customerDumpRates.includes(key));
    return c.map((item) => {
      const displayTitle = customStartCase(item, "");
      return (
        <option value={item}>
          {displayTitle}
        </option>
      )
    })
  }

  getFixedItems = async () => {
    const temp = []
    const uniqueValue = []
    const { initialValues } = this.state
     const {
      value:{user}
    } = await this.props.fetchMe();
    const payload={
      companyId:user?.company?._id
    }
    const {
      value: { data },
    } = await this.props.getFixedItems(payload);
    if (!this.state.isEdit) {
      data.fixedItems?.length > 0 && data.fixedItems.map(function (value, index) {
        if (initialValues.additionalItems[index]?.name === value.fieldName.toLowerCase()) {
          temp.push({
            label: value.fieldName,
            name: value.fieldName.toLowerCase(),
            value: parseFloat(String(initialValues.additionalItems[index].value).replace(/[^\d.]/g, '')),
            saleTax:false,
          },)
        } else {
          temp.push({
            label: value.fieldName,
            name: value.fieldName.toLowerCase(),
            value: parseFloat(String(value.name).replace(/[^\d.]/g, '')),
            saleTax:false,
          },)
        }
        return value
      })
      this.setState({ fixedItemslist: temp, initialValues: { ...this.state.initialValues, additionalItems: temp } })
    } else {

      let initialValues = this.state.initialValues;
      var uniqueResultTwo = data?.fixedItems !== undefined && data?.fixedItems.filter(function (obj) {
        return !initialValues.additionalItems.some(function (obj2) {
          return obj.fieldName === obj2.label;
        });
      });
      uniqueResultTwo?.length > 0 && uniqueResultTwo.map(function (value) {
        uniqueValue.push({
          label: value.fieldName,
          name: value.fieldName.toLowerCase(),
          value: parseFloat(String(value.name).replace(/[^\d.]/g, '')),
          saleTax:false,
        },)
        return value
      })

      let additionalItems = this.state.initialValues.additionalItems.concat(uniqueValue);
      this.setState({
        initialValues: { ...initialValues, additionalItems: additionalItems },
      });

    }


  }
  fetchMe = async () => {
    const {
      value:{user}
    } = await this.props.fetchMe();
    this.setState({companyId:user?.company?._id ,isSalesTax:user?.company?.isSalesTax})
  }
  initialData(pricingData) {
    const initialValues = { ...this.state.initialValues };
    const pricingTier = pricingData.length > 0 ? pricingData[0] : pricingData;
    for (let k in initialValues) {
      if (pricingTier?.additionalItems[k]) {
        initialValues[k] = pricingTier.additionalItems[k];
      }
      for (let i = 0; i < pricingTier.additionalItems.length; i++) {
        if (pricingTier.additionalItems[i].name === k) {
          initialValues[k] = pricingTier.additionalItems[i].value;
        }
      }
    }
    initialValues.dumpRates = { ...pricingTier.dumpRates };
    initialValues.pricingTier = pricingTier?.pricingTier;
    initialValues.additionalItems = pricingTier?.additionalItems;
    initialValues.minimumPrice = pricingTier?.minimumPrice;
    initialValues.capoutPrice = pricingTier?.capoutPrice;
    this.setState({
      initialValues,
      id: pricingTier._id,
    });
  }

  handleUpadte = async ({ mattress, ...values }) => {
    const updatedata = this.state.initialValues;
    const id = this.state.id;
    const payload = {
      dumpRates: updatedata?.dumpRates,
      additionalItems: updatedata?.additionalItems,
      capoutPrice: updatedata?.dumpRates?.capoutPrice,
      minimumPrice: updatedata?.dumpRates?.minimumPrice,
      pricingTier: updatedata?.pricingTier,
      id: id,
      companyId:this.state.companyId,
      salesTaxPricingTier:{
        dumpRates:this.state.initialValues.saleTaxToggles,
        additionalitems:this.state.initialValues.additionalItems,
        // additionalItems:this.state.saleTaxToggles.additionalItems
      }
    };
    const {
      value: { status, message },
    } = await this.props.editPricingTier(payload);
    if (status) {
      setTimeout(() => {
        this.props.onCloseClick()
      }, 800);
    } else {
      if (message === 'Pricing tier already exists.') {
        this.setState({ isExistPriceTierName: message, isDisable: true });
      }
    }
  };

  handleSubmit = async (
    {
      dumpRates,
      mattress,
      tire,
      sofa,
      tv,
      refrigerator,
      appliances,
      hardToHandle,
      overage,
      waitTime,
      travelFee,
      capoutPrice,
      minimumPrice,
      pricingTier,
      fixedItemErr,
      ...values
    },
    { setSubmitting, }
  ) => {
    setSubmitting(true);
    let objDefaultOption = {};
    let objTons = {};
    let objYards = {};
    for (let k in dumpRates) {
      if (k !== 'defaultOption') {
        if (dumpRates[k] !== '') {
          let value = parseFloat(String(dumpRates[k]).replace(/[^\d]/g, ''));
          dumpRates[k] = value;
          if (k.indexOf('Ton') !== -1) {
            objTons[k] = dumpRates[k];
          } else {
            objYards[k] = dumpRates[k];
          }
        } else {
          dumpRates[k] = '';
        }
      } else {
        objDefaultOption[k] = dumpRates[k];
      }
    }


    const additionalItems = this.state.initialValues.additionalItems
    const payload = {
      values,
      dumpRates,
      additionalItems,
      capoutPrice: dumpRates.capoutPrice,
      minimumPrice: dumpRates.minimumPrice,
      pricingTier,
      companyId:this.state.companyId,
      salesTaxPricingTier:{
        dumpRates:this.state.initialValues.saleTaxToggles,
        additionalitems:this.state.initialValues.additionalItems,
      }
    };
    const {
      value: { status, message },
    } = await this.props.createPricingTier(payload);
    if (status) {
      setTimeout(() => {
        this.props.onCloseClick()
      }, 800);
    } else {
      if (message === 'Pricing tier already exists.') {
        this.setState({ isExistPriceTierName: message, isDisable: true });
      }
    }
  };

  setDefaultOption(e, values, setValues) {
    const { initialValues } = this.state;
    setValues({
      ...values,
      dumpRates: { ...values.dumpRates, defaultOption: e.target.value },
    });
    this.setState({
      initialValues: {
        ...initialValues,
        dumpRates: {
          ...initialValues.dumpRates,
          defaultOption: e.target.value,
        },
      },
    });
  }

  setInitialValue(field, value, values, setValues) {
    const { initialValues } = this.state;
    setValues({ ...values, [field]: value });
    this.setState({ initialValues: { ...initialValues, [field]: value } });
    if (field === 'pricingTier') {
      this.setState({ isExistPriceTierName: '' });
    }
  }

  setInitialValues(e, values, setValues) {
    const { initialValues } = this.state;
    const value =parseFloat(String(e.target.value).replace(/[^\d.]/g, ''));
    setValues({ ...values, [e.target.name]: value });
    const additionalItems = initialValues?.additionalItems;

    additionalItems.map((item) => {
      if (e.target.name === item?.name) {
        item.value = value;
      }
      return null;
    });
    this.setState({
      initialValues: { ...initialValues, additionalItems: additionalItems },
    });
    this.setState({
      initialValues: { ...initialValues, [e.target.name]: value },
    });
  }

  setDefaultValues(field, value, values, setValues) {
    const { initialValues } = this.state;
    value = parseFloat(String(value).replace(/[^\d.]/g, ''));
    setValues({
      ...values,
      dumpRates: { ...values.dumpRates, [field]: value },
    });
    this.setState({
      initialValues: {
        ...initialValues,
        dumpRates: { ...initialValues.dumpRates, [field]: value },
      },
    });
  }

  handleSwitchChange = (name, checked,values,setValues) => {
    const {initialValues} = this.state;
     setValues({
      ...values,
      saleTaxToggles: { ...values.saleTaxToggles, [name]: checked },
    });
    
     this.setState({
       initialValues: {
         ...initialValues,
         saleTaxToggles: { ...initialValues.saleTaxToggles, [name]: checked  },
       },
     });
   };

   handleFixedItemSwitchChange = (name, checked, values, setValues) => {
    const { initialValues } = this.state;
    const additionalItems = initialValues.additionalItems;
  
    // Update the 'additionalitemsToggle' field in the values object
    setValues({
      ...values,
      additionalitemsToggle: { ...values.additionalitemsToggle, [name]: checked },
    });
  
    // Create a new 'additionalitemsToggle' array with the updated saleTax values
    const updatedAdditionalItemsToggle = additionalItems.map((item) => {
      if (name === item?.name) {
        return { ...item, saleTax: checked };
      }
      return item;
    });
  
    // Update the state with the new 'additionalitemsToggle' array
    this.setState({
      initialValues: {
        ...initialValues,
        additionalItems: updatedAdditionalItemsToggle,
      },
      additionalitemsToggle: updatedAdditionalItemsToggle,
    });
  };
  
  

  toCamelCaseWithoutSpaces = (inputString) => {
    // Split the input string into words
    const words = inputString.split(' ');
    // Capitalize the first letter of each word except the first one
    const camelCaseWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    // Join the words back together
    return camelCaseWords.join(' ');
  }
  createCustomField = async () => {
    const { modalTitle, initialValues } = this.state;
    if (this.state.fieldName === "") {
      this.setState({ addFixedItemsErrorMsg: "Custom field is required" })
    } else {      
      if (modalTitle === 'Custom Fixed Item') {
        let fieldName = this.state.fieldName?.replace(/\s+/g, ' ')?.trim();
        let words = fieldName.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        fieldName = words.join(" ")
        const payload = {
          fieldName: fieldName,
          companyId: this.state.companyId
        };
        const {
          value: { status },
        } = await this.props.addFixedItems(payload);
        if (status) {
          this.setState({ isModelOpen: false })
          this.getFixedItems()
        }else{
          this.setState({ fixedItemErr: true })
        }
      } else {
        let fieldName = this.toCamelCaseWithoutSpaces(this.state.fieldName?.replace(/\s+/g, ' ')?.trim());
        let dumpRates = initialValues?.dumpRates;
        const fieldNameUpdated = modalTitle === 'Custom Ton Rate' ? `pricePerTon${fieldName}` : `pricePerYard${fieldName}`
        //// Validation
        if (this.validateFieldName(fieldName, fieldNameUpdated)) {
          let customerDumpRates = this.state.customerDumpRates;
          customerDumpRates.push(fieldNameUpdated);
          dumpRates = { ...dumpRates, [fieldNameUpdated]: '' };
          this.setState({
            initialValues: { ...initialValues, dumpRates }
          }, () => {
            this.setState({ isModelOpen: false,  customerDumpRates });
          });
        }
      }
    }
  }

  validateFieldName = (fieldName, fieldNameUpdated) => {
    const { initialValues } = this.state;
    if (_.toLower(fieldName) === 'ton' || _.toLower(fieldName) === 'yard' || initialValues?.dumpRates[`${fieldNameUpdated}`] !== undefined) {
      this.setState({ fixedItemErr: true });
      return false;
    }
    this.setState({ fixedItemErr: false });
    return true;
  }

  getCustomDumpRates = (initialValues, defaultDumpRates, values, setValues, type) => {

    return Object.keys(initialValues.dumpRates).map((item) =>  {
      // const displayTitle = _.startCase(item.replace(type, ''));
      const displayTitle = customStartCase(item, type)
      if (!defaultDumpRates.includes(item) && item.includes(type)) {
        return(
          <div key={item} className="col-md-6">
            <div className="form-group material-textfield">
              <NumberFormat
                allowEmptyFormatting={true}
                prefix={"$"}
                decimalScale={2}
                thousandSeparator={true}
                displayType={"input"}
                type="text"
                className="form-control material-textfield-input"
                onValueChange={(_values) => {
                  const { formattedValue } = _values;
                  this.setDefaultValues(
                    `${item}`,
                    formattedValue,
                    values,
                    setValues
                  );
                }}
                value={values.dumpRates[item]} // removed the template literal here
                name={`dumpRates.${item}`}
                required
              />
              <ErrorMessage
                component="span"
                name={`dumpRates.${item}`}
                className="invalid-feedback d-block"
              />
              <label className="material-textfield-label">{displayTitle}</label>
            </div>
            <DumpRateSalesTaxSwitch
              value={initialValues.saleTaxToggles[item]}
              onChange={(checked) => this.handleSwitchChange(item, checked, values, setValues)}
              isSalesTax ={this.state.isSalesTax}
            />
          </div>
        )
      }
      return false;
    })
  }
  render() {
    const { initialValues, isEdit, isExistPriceTierName, defaultDumpRates } = this.state;

    return (
      <div>
        <ReactModal
          isOpen={this.props.isCreateEditPricingModelOpen}
          onRequestClose={this.props.onCloseClick}
        >
          <div className='react-modal-dialog react-modal-dialog-centered pricingtier__modal
'>
            <div className='react-modal-header'>
              <h5 className='react-modal-title'>Pricing Tier Dump Rates</h5>
              <button
                type='button'
                className='btn react-modal-close'
                onClick={
                  this.props.onCloseClick
                }
              >
                <CloseIcon />
              </button>
            </div>

            <div className='react-modal-body' >
              <div className="settings__container">
                {/* <div className="card-body"> */}
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={PricingTierCreateEdit(initialValues)}
                  onSubmit={(values, formikProps) => {
                    this.setState({ pleaseWait: true, isDisable: true })
                    if (isEdit) {
                      this.handleUpadte(values, formikProps);
                    } else {
                      this.handleSubmit(values, formikProps);
                    }
                  }}>
                  {({
                    values,
                    handleSubmit,
                    setValues,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <ErrorFocus />
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='form-group material-textfield'>
                            <input
                              type='text'
                              value={values.pricingTier}
                              onChange={(e) => {
                                this.setInitialValue(
                                  'pricingTier',
                                  e.target.value,
                                  values,
                                  setValues
                                )
                                this.setState({ isDisable: false, pleaseWait: false })
                              }}
                              className='form-control material-textfield-input'
                              name='pricingTier'
                              required
                            />
                            {isExistPriceTierName ? (
                              <span style={{ color: 'red' }}>
                                Pricing Tier Name already exists
                              </span>
                            ) : (
                              ''
                            )}
                            <ErrorMessage
                              component='span'
                              name='pricingTier'
                              className='invalid-feedback d-block'
                            />
                            <label className='material-textfield-label'>
                              Pricing Tier Name<span>*</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='card'>
                        {/* <div className='card-header d-flex flex-column align-items-start' style={{'padding': '0', minHeight: 'auto'}}>
                          <div className='row'>
                            <div className='col-md-12'>
                              <h5 className='card-title'>Dump Rates</h5>
                            </div>
                          </div>
                        </div> */}
                        <div className='card-body'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='form-group material-textfield'>
                                <select
                                  id='dumpRates'
                                  name='dumpRates.defaultOption'
                                  className='form-control material-textfield-input custom-select w-150 pr-22'
                                  value={values?.dumpRates?.defaultOption}
                                  onChange={(e) =>
                                    this.setDefaultOption(e, values, setValues)
                                  }
                                  required
                                  onFocus={() =>
                                    this.setState({ onSelectFocus: true })
                                  }
                                  onBlur={() =>
                                    this.setState({ onSelectFocus: false })
                                  }>
                                  <option value=''>
                                    {!this.state.onSelectFocus
                                      ? ''
                                      : 'Select Dump Rate'}
                                  </option>
                                  {/* <option value='pricePerTonLight'>
                                    Price per ton (light)
                                  </option>
                                  <option value='pricePerTonHeavy'>
                                    Price per ton (heavy)
                                  </option>
                                  <option value='pricePerTonMixedCD'>
                                    Price per ton Mixed C&D
                                  </option>
                                  <option value='pricePerTonWood'>
                                    Price per ton Wood
                                  </option>
                                  <option value='pricePerTonMetal'>
                                    Price per ton Metal
                                  </option>
                                  <option value='pricePerTonRoofing'>
                                    Price per ton Roofing
                                  </option>
                                  <option value='pricePerTonMasonry'>
                                    Price per ton Masonry
                                  </option>
                                  <option value='pricePerTonBrick'>
                                    Price per ton Brick
                                  </option>
                                  <option value='pricePerTonDirt'>
                                    Price per ton Dirt
                                  </option>
                                  <option value='pricePerTonConcrete'>
                                    Price per ton Concrete
                                  </option>
                                  <option value='pricePerTonCD'>
                                    Price per ton C&D
                                  </option>
                                  <option value='pricePerTonLowRecovery'>
                                    Price per ton Low Recovery
                                  </option>
                                  <option value='pricePerTonOpenContainer'>
                                    Price per ton Open Container
                                  </option>
                                  <option value='pricePerTonClosedContainer'>
                                    Price per ton Closed Container
                                  </option>
                                  <option value='pricePerTonTrailer'>
                                    Price per ton Trailer
                                  </option>
                                  <option value='pricePerTonAsbestos'>
                                    Price per ton Asbestos
                                  </option>
                                  <option value='pricePerYardLight'>
                                    Price per yard (light)
                                  </option>
                                  <option value='pricePerYardHeavy'>
                                    Price per yard (heavy)
                                  </option>
                                  <option value='pricePerYardMixedCD'>
                                    Price per yard Mixed C&D
                                  </option>
                                  <option value='pricePerYardWood'>
                                    Price per yard Wood
                                  </option>
                                  <option value='pricePerYardMetal'>
                                    Price per yard Metal
                                  </option>
                                  <option value='pricePerYardRoofing'>
                                    Price per yard Roofing
                                  </option>
                                  <option value='pricePerYardMasonry'>
                                    Price per yard Masonry
                                  </option>
                                  <option value='pricePerYardBrick'>
                                    Price per yard Brick
                                  </option>
                                  <option value='pricePerYardDirt'>
                                    Price per yard Dirt
                                  </option>
                                  <option value='pricePerYardConcrete'>
                                    Price per yard Concrete
                                  </option>
                                  <option value='pricePerYardCD'>
                                    Price per yard C&D
                                  </option>
                                  <option value='pricePerYardLowRecovery'>
                                    Price per yard Low Recovery
                                  </option>
                                  <option value='pricePerYardOpenContainer'>
                                    Price per yard Open Container
                                  </option>
                                  <option value='pricePerYardClosedContainer'>
                                    Price per yard Closed Container
                                  </option>
                                  <option value='pricePerYardTrailer'>
                                    Price per yard Trailer
                                  </option>
                                  <option value='pricePerYardAsbestos'>
                                    Price per yard Asbestos
                                  </option> */}
                                  {this.getDumpRateOptions()}
                                </select>
                                <ErrorMessage
                                  component='span'
                                  className='invalid-feedback d-block'
                                  name='dumpRates.defaultOption'
                                />
                                <label className='material-textfield-label'>
                                  Set a default option<span>*</span>
                                </label>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>

                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonLight',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerTonLight}
                                  name='dumpRates.pricePerTonLight'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='displayName'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Price per ton (light)
                                </label>
                              </div>
                                <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonLight}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonLight', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>

                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  decimalScale={2}
                                  type='text'
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonHeavy',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerTonHeavy}
                                  name='dumpRates.pricePerTonHeavy'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='email'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Price per ton (heavy)
                                </label>
                              </div>
                                <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonHeavy}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonHeavy', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>

                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerYardLight',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardLight}
                                  name='dumpRates.pricePerYardLight'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='phone'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Price per yard (light)
                                </label>
                              </div>
                                <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardLight}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardLight', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>

                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerYardHeavy',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardHeavy}
                                  name='dumpRates.pricePerYardHeavy'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='phone'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Price per yard (heavy)
                                </label>
                              </div>
                                <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardHeavy}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardHeavy', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-12'>
                              <h5 className='card-title title_font'>Price per ton</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  decimalScale={2}
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonMixedC&D',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates['pricePerTonMixedC&D']}
                                  name="dumpRates.pricePerTonMixedC&D"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonMixedC&D"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Mixed C&D</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles['pricePerYardMixedC&D']}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonMixedC&D', checked,values, setValues)}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  decimalScale={2}
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonWood',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonWood}
                                  name="dumpRates.pricePerTonWood"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonWood"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Wood</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonWood}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonWood', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonMetal',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonMetal}
                                  name="dumpRates.pricePerTonMetal"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonMetal"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Metal</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonMetal}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonMetal', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonRoofing',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonRoofing}
                                  name="dumpRates.pricePerTonRoofing"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonRoofing"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Roofing</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonRoofing}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonRoofing', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  decimalScale={2}
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonMasonry',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonMasonry}
                                  name="dumpRates.pricePerTonMasonry"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonMasonry"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Masonry</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonMasonry}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonMasonry', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonBrick',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonBrick}
                                  name="dumpRates.pricePerTonBrick"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonBrick"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Brick</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonBrick}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonBrick', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>

                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  decimalScale={2}
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonDirt',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonDirt}
                                  name="dumpRates.pricePerTonDirt"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonDirt"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Dirt</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonDirt}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonDirt', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  decimalScale={2}
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonConcrete',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonConcrete}
                                  name="dumpRates.pricePerTonConcrete"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonConcrete"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Concrete</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonConcrete}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonConcrete', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  decimalScale={2}
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonC&D',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates['pricePerTonC&D']}
                                  name="dumpRates.pricePerTonC&D"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonC&D"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">{`C&D`}</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles['pricePerTonC&D']}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonC&D', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  decimalScale={2}
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonLowRecovery',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonLowRecovery}
                                  name="dumpRates.pricePerTonLowRecovery"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonLowRecovery"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Low Recovery</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonLowRecovery}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonLowRecovery', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  decimalScale={2}
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonOpenContainer',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonOpenContainer}
                                  name="dumpRates.pricePerTonOpenContainer"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonOpenContainer"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Open Container</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonOpenContainer}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonOpenContainer', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  decimalScale={2}
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonClosedContainer',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonClosedContainer}
                                  name="dumpRates.pricePerTonClosedContainer"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonClosedContainer"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Closed Container</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonClosedContainer}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonClosedContainer', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  decimalScale={2}
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonTrailer',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonTrailer}
                                  name="dumpRates.pricePerTonTrailer"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonTrailer"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Trailer</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonTrailer}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonTrailer', checked)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={"$"}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  displayType={"input"}
                                  type="text"
                                  className="form-control material-textfield-input"
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerTonAsbestos',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values.dumpRates.pricePerTonAsbestos}
                                  name="dumpRates.pricePerTonAsbestos"
                                  required
                                />
                                <ErrorMessage
                                  component="span"
                                  name="dumpRates.pricePerTonAsbestos"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">Asbestos</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerTonAsbestos}
                                  onChange={(checked) => this.handleSwitchChange('pricePerTonAsbestos', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            { this.getCustomDumpRates(initialValues, defaultDumpRates, values, setValues, 'pricePerTon') }
                            <div className='col-md-6'>
                              <div className="form-group material-textfield">
                                <button className="btn btn-dark btn-lg w-100" type="button" onClick={() => this.setState({ isModelOpen: true , modalTitle: 'Custom Ton Rate', fixedItemErr:false})}>
                                  Add Custom Ton Rate
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-12'>
                              <h5 className='card-title title_font'>Price per yard</h5>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerYardMixedC&D',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates['pricePerYardMixedC&D']}
                                  name='dumpRates.pricePerYardMixedC&D'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardMixedC&D'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Mixed C&D
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles['pricePerYardMixedC&D']}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardMixedC&D', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerYardWood',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardWood}
                                  name='dumpRates.pricePerYardWood'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardWood'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Wood
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardWood}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardWood', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;

                                    this.setDefaultValues(
                                      'pricePerYardMetal',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardMetal}
                                  name='dumpRates.pricePerYardMetal'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardMetal'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Metal
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardMetal}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardMetal', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;

                                    this.setDefaultValues(
                                      'pricePerYardRoofing',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardRoofing}
                                  name='dumpRates.pricePerYardRoofing'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardRoofing'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Roofing
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardRoofing}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardRoofing', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;

                                    this.setDefaultValues(
                                      'pricePerYardMasonry',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardMasonry}
                                  name='dumpRates.pricePerYardMasonry'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardMasonry'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Masonry
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardMasonry}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardMasonry', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;

                                    this.setDefaultValues(
                                      'pricePerYardBrick',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardBrick}
                                  name='dumpRates.pricePerYardBrick'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardBrick'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Brick
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardBrick}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardBrick', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;

                                    this.setDefaultValues(
                                      'pricePerYardDirt',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardDirt}
                                  name='dumpRates.pricePerYardDirt'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardDirt'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Dirt
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardDirt}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardDirt', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;

                                    this.setDefaultValues(
                                      'pricePerYardConcrete',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardConcrete}
                                  name='dumpRates.pricePerYardConcrete'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardConcrete'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Concrete
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardConcrete}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardConcrete', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;

                                    this.setDefaultValues(
                                      'pricePerYardC&D',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates['pricePerYardC&D']}
                                  name='dumpRates.pricePerYardC&D'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardC&D'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>{`C&D`}</label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles['pricePerYardC&D']}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardC&D', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;

                                    this.setDefaultValues(
                                      'pricePerYardLowRecovery',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardLowRecovery}
                                  name='dumpRates.pricePerYardLowRecovery'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardLowRecovery'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Low Recovery
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardLowRecovery}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardLowRecovery', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerYardOpenContainer',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardOpenContainer}
                                  name='dumpRates.pricePerYardOpenContainer'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardOpenContainer'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Open Container
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardOpenContainer}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardOpenContainer', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerYardClosedContainer',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={
                                    values?.dumpRates?.pricePerYardClosedContainer
                                  }
                                  name='dumpRates.pricePerYardClosedContainer'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardClosedContainer'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Closed Container
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardClosedContainer}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardClosedContainer', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerYardTrailer',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardTrailer}
                                  name='dumpRates.pricePerYardTrailer'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardTrailer'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Trailer
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardTrailer}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardTrailer', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group material-textfield'>
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  prefix={'$'}
                                  thousandSeparator={true}
                                  displayType={'input'}
                                  type='text'
                                  decimalScale={2}
                                  className='form-control material-textfield-input'
                                  onValueChange={(_values) => {
                                    const { formattedValue } = _values;
                                    this.setDefaultValues(
                                      'pricePerYardAsbestos',
                                      formattedValue,
                                      values,
                                      setValues
                                    );
                                  }}
                                  value={values?.dumpRates?.pricePerYardAsbestos}
                                  name='dumpRates.pricePerYardAsbestos'
                                  required
                                />
                                <ErrorMessage
                                  component='span'
                                  name='dumpRates.pricePerYardAsbestos'
                                  className='invalid-feedback d-block'
                                />
                                <label className='material-textfield-label'>
                                  Asbestos
                                </label>
                              </div>
                              <DumpRateSalesTaxSwitch
                                  value={initialValues.saleTaxToggles.pricePerYardAsbestos}
                                  onChange={(checked) => this.handleSwitchChange('pricePerYardAsbestos', checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                            </div>
                            { this.getCustomDumpRates(initialValues, defaultDumpRates, values, setValues, 'pricePerYard') }
                            <div className='col-md-6'>
                              <div className="form-group material-textfield">
                                <button className="btn btn-dark btn-lg w-100" type="button" onClick={() => this.setState({ isModelOpen: true , modalTitle: 'Custom Yard Rate', fixedItemErr:false})}>
                                  Add Custom Yard Rate
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-12'>
                              <h5 className='card-title title_font'>Fixed Items</h5>
                            </div>
                          </div>
                          {!this.state.isEdit ?
                            <div className='row'>
                              {this.state.fixedItemslist?.length > 0 && this.state.fixedItemslist.map(data => {
                                return (
                                  data.label !== "Minimum Price" && data.label !== "Capout Price Per Yard" &&
                                  <div className='col-md-6' key={data.label}>
                                    <div className='form-group material-textfield'>
                                      <NumberFormat
                                        allowEmptyFormatting={true}
                                        prefix={'$'}
                                        thousandSeparator={true}
                                        displayType={'input'}
                                        type='text'
                                        onChange={(e) => {
                                          this.setInitialValues(e, values, setValues)
                                        }}
                                        className='form-control material-textfield-input'
                                        value={values?.name}
                                        decimalScale={2}
                                        name={data.name}
                                        required
                                      />
                                      <label className='material-textfield-label'>
                                        {data.label}
                                      </label>
                                    </div>
                                      <DumpRateSalesTaxSwitch
                                        value={initialValues.additionalitemsToggle[data.label]}
                                        onChange={(checked) => this.handleFixedItemSwitchChange(data.label, checked, values, setValues)}
                                        isSalesTax ={this.state.isSalesTax}
                                      />
                                  </div>
                                )
                              })}
                              <div className='col-md-6'>
                                <div className="form-group material-textfield">
                                  <button className="btn btn-dark btn-lg w-100" type="button" onClick={() => this.setState({ isModelOpen: true, modalTitle: 'Custom Fixed Item', fixedItemErr:false})}>
                                    Add Custom Fixed Item
                                  </button>
                                </div>

                              </div>
                              <div className="col-md-12">
                                <div className="form-group material-textfield">
                                  <NumberFormat
                                    allowEmptyFormatting={true}
                                    prefix={"$"}
                                    thousandSeparator={true}
                                    displayType={"input"}
                                    type="text"
                                    decimalScale={2}
                                    className="form-control material-textfield-input"
                                    onValueChange={(_values) => {
                                      const { formattedValue } = _values;
                                      this.setDefaultValues(
                                        'capoutPrice',
                                        formattedValue,
                                        values,
                                        setValues
                                      );
                                    }}
                                    value={values?.dumpRates?.capoutPrice}
                                    name='dumpRates.capoutPrice'
                                    required
                                  />
                                  <ErrorMessage component="span" name="capoutPrice" className="invalid-feedback d-block" />
                                  <label className="material-textfield-label">Capout Price Per Yard</label>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group material-textfield">
                                  <NumberFormat
                                    allowEmptyFormatting={true}
                                    prefix={"$"}
                                    thousandSeparator={true}
                                    displayType={"input"}
                                    type="text"
                                    decimalScale={2}
                                    className="form-control material-textfield-input"
                                    onValueChange={(_values) => {
                                      const { formattedValue } = _values;
                                      this.setDefaultValues(
                                        'minimumPrice',
                                        formattedValue,
                                        values,
                                        setValues
                                      );
                                    }}
                                    value={values?.dumpRates?.minimumPrice}
                                    name='dumpRates.minimumPrice'
                                    required
                                  />
                                  <ErrorMessage component="span" name="minimumPrice" className="invalid-feedback d-block" />
                                  <label className="material-textfield-label">Minimum Price</label>
                                </div>
                              </div>
                            </div>
                            :
                            <div className='row'>
                              {this.state.initialValues?.additionalItems?.length > 0 && this.state.initialValues?.additionalItems.map(data => {
                                return (
                                  data.label !== "Minimum Price" && data.label !== "Capout Price Per Yard" &&
                                  <div className='col-md-6' key={data.label}>
                                    <div className='form-group material-textfield'>
                                      <NumberFormat
                                        allowEmptyFormatting={true}
                                        prefix={'$'}
                                        thousandSeparator={true}
                                        displayType={'input'}
                                        type='text'
                                        onChange={(e) => {
                                          this.setInitialValues(e, values, setValues)
                                        }

                                        }
                                        className='form-control material-textfield-input'
                                        value={data.value}
                                        decimalScale={2}
                                        name={data.name}
                                        required
                                      />
                                      <label className='material-textfield-label'>
                                        {data.label}
                                      </label>
                                    </div>
                                    <DumpRateSalesTaxSwitch
                                  value={data.saleTax || false}
                                  onChange={(checked) => this.handleFixedItemSwitchChange(data.name, checked,values, setValues)}
                                  isSalesTax ={this.state.isSalesTax}
                                />
                                  </div>
                                )
                              })}
                              <div className='col-md-6'>
                                <div className="form-group material-textfield">
                                  <button className="btn btn-dark btn-lg w-100" type="button" onClick={() => this.setState({ isModelOpen: true, modalTitle: 'Custom Fixed Item', fixedItemErr:false })}>
                                    Add Custom Fixed Item
                                  </button>
                                </div>

                              </div>
                              <div className="col-md-12">
                                <div className="form-group material-textfield">
                                  <NumberFormat
                                    allowEmptyFormatting={true}
                                    prefix={"$"}
                                    thousandSeparator={true}
                                    displayType={"input"}
                                    type="text"
                                    decimalScale={2}
                                    className="form-control material-textfield-input"
                                    onValueChange={(_values) => {
                                      const { formattedValue } = _values;
                                      this.setDefaultValues(
                                        'capoutPrice',
                                        formattedValue,
                                        values,
                                        setValues
                                      );
                                    }}
                                    value={values?.dumpRates?.capoutPrice}
                                    name='dumpRates.capoutPrice'
                                    required
                                  />
                                  <ErrorMessage component="span" name="capoutPrice" className="invalid-feedback d-block" />
                                  <label className="material-textfield-label">Capout Price Per Yard</label>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group material-textfield">
                                  <NumberFormat
                                    allowEmptyFormatting={true}
                                    prefix={"$"}
                                    thousandSeparator={true}
                                    displayType={"input"}
                                    type="text"
                                    decimalScale={2}
                                    className="form-control material-textfield-input"
                                    onValueChange={(_values) => {
                                      const { formattedValue } = _values;
                                      this.setDefaultValues(
                                        'minimumPrice',
                                        formattedValue,
                                        values,
                                        setValues
                                      );
                                    }}
                                    value={values?.dumpRates?.minimumPrice}
                                    name='dumpRates.minimumPrice'
                                    required
                                  />
                                  <ErrorMessage component="span" name="minimumPrice" className="invalid-feedback d-block" />
                                  <label className="material-textfield-label">Minimum Price</label>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className='col-lg-12'>
                        <button type='submit' className='btn btn-dark btn-lg w-100' disabled={this.state.isDisable}>
                          {isExistPriceTierName ? isEdit ? 'Update' : 'Save' : this.state.pleaseWait ? "Please wait..." : isEdit ? 'Update' : 'Save'}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
                {this.state.isModelOpen && (
                  <div>
                    <ReactModal
                      isOpen={this.state.isModelOpen}
                    // className='scale__setting-modal'
                    >
                      <div className='react-modal-dialog react-modal-dialog-centered HeightAutoModal'>
                        <div className='react-modal-header'>
                          <h5 className='react-modal-title'>{this.state.modalTitle}</h5>
                          <button
                            type='button'
                            className='btn react-modal-close'
                            onClick={() => this.setState({ isModelOpen: false })}>
                            <CloseIcon />
                          </button>
                        </div>
                        <div className='react-modal-body'>
                          <div className='scale-setting-container'>
                            <div className='scale-setting-wrapper'>
                              <div className="row">
                                <div className='col-md-12'>
                                  <div className='form-group material-textfield'>
                                    <input
                                      type='text'
                                      name='fieldName'
                                      className='form-control material-textfield-input'
                                      onChange={(e) => {
                                        this.setState({ fieldName: e.target.value, addFixedItemsErrorMsg: "",fixedItemErr:false })
                                      }}
                                      required
                                    />
                                    <p className='invalid-feedback d-block'>{this.state.addFixedItemsErrorMsg === "" ? "" : this.state.addFixedItemsErrorMsg}</p>
                                    {this.state.fixedItemErr &&  <p className='invalid-feedback d-block'>{"Field already exist"}</p>}
                  
                                    {/* <ErrorMessage
                                    component='span'
                                    name='scaleName'
                                    className='invalid-feedback d-block'
                                  /> */}
                                    <label className='material-textfield-label'>
                                      { this.state.modalTitle === 'Custom Fixed Item' ? 'Name of new custom item' : 'Name of new custom rate'}<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group material-textfield">
                                    <button className="btn btn-dark btn-lg w-100" type="button" disabled={this.state.addFixedItemsErrorMsg === "" ? false : true} onClick={() => this.createCustomField()}>
                                      Create
                                    </button>
                                  </div>
                                  
                                </div>
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ReactModal>
                  </div>
                )
                }
                {/* </div> */}
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
